/*
 * @Author: daipeng
 * @Date: 2018-09-19 20:32:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-27 15:21:26
 * @Description: 动画漫信息mixin
 * @Company: 成都二次元动漫
 */

import { anComicCard } from '@/components/comic';

export default {
    data() {
        const validateAuthPlatforms = (rule, value, callback) => {
            if (!value.length) {
                callback(new Error('请选择当前动态漫发布平台'));
            }
            // 如果选了定时发布 但没选时间
            if (value.is_timing === 1 && !value.publish_time) {
                callback(new Error('请填写定时发布时间'));
            }
            callback();
        };
        return {
            publicClass: [],
            comicData: {
                animation_id: undefined,
                animation_name: '',
                author_name: '',
                animation_brief: '',
                animation_cover_3_4: '', // 封面图1 3:4
                animation_cover_4_3: '', // 封面图1 4:3
                animation_cover_2_1: '', // 封面图2 16:9
                serial_status: null, // 6 连载状态
                comicType: null, // 13 漫画类型
                audience_status: null, // 14 受众
                theme_ids: [], // 17 题材
                auth_platforms: [], // 漫画发布平台
                agreement: false,
                since_year: '',
                origin_comic_name: undefined,
                origin_comic_id: undefined,
                is_timing: 0,
                animation_cover_domain: '',
                allow_down: 1,
                chapter_price: null // 章节价格
            },
            comicDataClone: {},
            comicDataRules: {
                animation_name: [
                    { pattern: /\S+/, required: true, type: 'string', message: '请输入动态漫名称，无需填写《》或其他符号', trigger: 'blur' },
                    { min: 1, max: 20, pattern: /\S+/, required: true, type: 'string', message: '动态漫名称最少1个字符，最多20个字符', trigger: 'change' }
                ],
                author_name: [
                    { required: true, message: '作者名不能为空', trigger: 'blur' }
                ],
                animation_brief: [
                    { required: true, pattern: /\S+/, type: 'string', message: '请输入关于作品的描述', trigger: 'blur' },
                    { min: 1, max: 200, pattern: /\S+/, type: 'string', message: '作品描述最多200个字', trigger: 'change' }
                ],
                animation_cover_3_4: [
                    { required: true, type: 'string', pattern: /\S+/, message: '封面地址不能为空' }
                ],
                animation_cover_4_3: [
                    { required: true, type: 'string', pattern: /\S+/, message: '封面地址不能为空' }
                ],
                animation_cover_2_1: [
                    { required: true, type: 'string', pattern: /\S+/, message: '封面地址不能为空' }
                ],
                serial_status: [
                    { required: true, message: '请选择连载状态', trigger: 'change' }
                ],
                comicType: [
                    { required: true, message: '请选择动态漫类型', trigger: 'change' }
                ],
                audience_status: [
                    { required: true, message: '请选择当前动态漫受众', trigger: 'change' }
                ],
                animation_feature: [
                    { required: true, message: '动态漫看点不能为空', trigger: 'blur' },
                    { min: 12, max: 18, message: '字数不足或超出12-18字，请检查后输入', trigger: 'blur' }
                ],
                update_cycle: [
                    { required: true, message: '更新规律不能为空', trigger: 'blur' },
                    { min: 1, max: 10, message: '字数不足或超出1-10字，请检查后输入', trigger: 'blur' }
                ],
                since_year: [
                    { required: true, message: '年份不能为空', trigger: 'blur' }
                ],
                theme_ids: [
                    { type: 'array', required: true, message: '请选择当前动态漫的题材', trigger: 'blur' },
                    { type: 'array', max: 3, message: '最多选择3个当前动态漫的题材', trigger: 'change' }
                ],
                allow_down: [
                    { required: true }
                ],
                auth_platforms: [
                    { required: true, validator: validateAuthPlatforms, trigger: 'change' }
                ],
                chapter_price: [
                    { required: true, message: '价格不能为空', trigger: 'blur' }
                ],
            }
        };
    },
    methods: {
        // 组装漫画数据
        formatComicData(publicClass, comicData) {
            this.publicClass = publicClass;
            if (!this.$utils.isEmptyObject(comicData)) {
                this.comicData = {
                    ...this.comicData,
                    ...comicData,
                    sourceObj: this.$utils.cloneDeep(comicData)
                };
                this.comicDataClone = this.$utils.cloneDeep(this.comicData);
            }
        },
        // 请求漫画分分类信息、漫画数据
        async getAllData(list) {
            return Promise.all(list).then(res => {
                const [publicClass, comicData = {}] = res;
                this.formatComicData(publicClass, comicData);
            });
        },
        // 获取漫画类型
        getPublicClass() {
            return this.$api('getPublicConfig').then(({ data }) => {
                return data.animation.class_configs;
            });
        },
        // 获取漫画内容
        getComicData(animation_id) {
            return this.$api('getAnComicInfo', { animation_id }).then(({ data }) => {
                if (this.$utils.isEmptyObject(data)) {
                    this.$message.error('当前动态漫不存在！');
                    return this.$router.back();
                } else return data;
            });
        },
        // 获取原作漫画列表
        getComicSelectList() {
            return this.$api('getComicSelectList').then(({ data }) => data);
        }
    },
    components: {
        anComicCard
    }
};
