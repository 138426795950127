/*
 * @Author: daipeng
 * @Date: 2018-09-05 19:03:07
 * @LastEditors: VSCode
 * @LastEditTime: 2019-03-18 16:07:22
 * @Description: 图片剪切
 * @Company: 成都二次元动漫
 */
import cropper from '@/components/cropper';

export default {
    data() {
        return {
            cropperData: {
                visible: false,
                image: null,
                options: {}
            }
        };
    },
    methods: {
        showCropper(visible, image, options) {
            this.cropperData.visible = visible;
            this.cropperData.image = image;
            this.cropperData.options = options;
        },
        closeCropper() {
            this.cropperData = this.$options.data().cropperData;
        }
    },
    components: {
        cropper
    }
};
