/*
 * @Author: daipeng
 * @Date: 2018-09-05 19:03:07
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-27 09:52:32
 * @Description: form表单mixins封装，主要是一些公用方法的封装，可以被覆盖
 * @Company: 成都二次元动漫
 */
import { isArray, isString, isUndefined } from '@/libs/utils';
export default {
    methods: {
        // 校验 promise
        validateForm(formName) {
            // eslint-disable-next-line
            if (isString(formName) && this.$refs[formName]) return new Promise((reslove, reject) => this.$refs[formName].validate(valid => {
                reslove(valid)
            }));
            else return (console.error('请确保输入有效的表单ref'), false);
        },
        // 清除校验结果
        clearFormValidate(formName, field) {
            if (isString(formName) && this.$refs[formName]) {
                const formComponent = this.$refs[formName];
                if (isString(field)) formComponent.validateField(field);
                else if (isArray(field) || isUndefined(field)) formComponent.clearValidate(field);
            } else return (console.error('请确保输入有效的表单ref'), false);
        },
        // 重置表单
        resetFormFields(formName) {
            if (isString(formName) && this.$refs[formName]) this.$refs[formName].resetFields();
            else return (console.error('请确保输入有效的表单ref'), false);
        }
    }
};
