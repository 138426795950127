import * as utils from '@/libs/utils';
import * as filters from '@/libs/filters';
import { directives, installDirective } from '@/libs/directives';
import { installPlugin } from '@/libs/plugins';

// 注册全局自定义过滤器
export const installFilters = Vue => {
    Object.keys(filters).forEach(key => {
        if (utils.isFunction(filters[key])) Vue.filter(key, filters[key]);
    });
};

export {
    utils,
    filters,
    directives,
    installDirective,
    installPlugin
};
