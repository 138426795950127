/*
 * @Author: daipeng
 * @Date: 2018-09-03 18:31:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-03 10:00:42
 * @Description: 接口map，可分割
 * @Company: 成都二次元动漫
 */
import { get, post, remove, put } from '@/api/request';

export default {
    /**
     * authorize
     */
    islogin: get('/api/login/islogin'),
    login: post('/api/login'),
    logout: post('/api/logout'),
    checkimagesformat: post('/api/chapter/checkimagesformat'), // 预检测章节图片格式 data={comicId, imageAddr}
    /**
     * 上传图片token
     */
    getComicCoverToken: get('/api/user/comic/cover/uploadtoken'), // 上传漫画封面授权 data={fileName,uploaderUid}
    getChapterContentToken: get('/api/user/comic/chapter/picture/uploadtoken'), // 上传漫画章节内容授权 data={comicId，chapterId，fileName,uploaderUid}
    getChapterCoverToken: get('/api/user/comic/chapter/cover/uploadtoken'), // 上传漫画章节封面授权 data={comicId，fileName,uploaderUid}
    decompressFile: get('/api/user/comic/chapter/compressedfile/uploadtoken'), // 压缩文件授权 data={co micId，fileName,uploaderUid}
    /**
     * 动态漫上传token
     */
    getAnComicCoverToken: get('/api/v2/user/animation/huawei/coversts'), // 上传动态漫画封面授权 data={[animation_id],fileName,type}
    getAnChapterCoverToken: get('/api/v2/user/animation/chapter/huawei/coversts'), // 获取动态漫章节封面上传sts   data={animation_id, [chapter_id],fileName,type}
    getRelatedVideoCoverToken: get('/api/v2/user/animation/correlation/huawei/coversts'), // 获取相关视频封面上传sts
    getAnChapterVideoToken: post('/api/v2/user/animation/chapter/video'), // 获取动态漫章节视频上传凭证
    getRelatedVideoToken: post('/api/v2/user/animation/correlation/video'), // 获取动态漫相关视频上传凭证
    getComicChapterVideoToken: post('/api/user/comic/chapter/video'), // 获取章节视频上传凭证
    getAnChapterVideoPlayAuth: get('/api/v2/user/animation/chapter/getvideoplayauth'), // 获取动态漫视频播放凭证
    getRelatedVideoPlayAuth: get('/api/v2/user/animation/correlation/getvideoplayauth'), // 获取相关视频播放凭证
    getComicVideoPlayAuth: get('/api/user/comic/chapter/getplayinfo'), // 获取漫画章节视频播放地址

    /**
     * 动态漫管理
     */
    getPublicConfig: get('/api/v2/publicconfigs'), // 获取公众配置
    addAnComic: post('/api/v2/user/animation/add'), // 增加漫画
    editAnComic: put('/api/v2/user/animation/info'), // 编辑动态漫主体
    getComicSelectList: get('/api/v2/user/animation/comicselectlist'), // 查询用于下拉选的漫画列表
    getAnComicList: get('/api/v2/user/animation/list'), // 获取用户动态漫列表
    getAnComicRelatedVideoList: get('/api/v2/user/animation/correlationlist'), // 查询当前动态漫的相关视频
    getAnComicInfo: get('/api/v2/user/animation/info'), // 获取用户动态漫详情
    submitAnAudit: put('/api/v2/user/animation/submit'), // 动态漫提交审核
    removeAnComic: remove('/api/v2/user/animation/delete'), // 删除未上架的动态漫
    displayAnComic: put('/api/v2/user/animation/showstatus'), // 动态漫上下架管理
    getAnComicCheckedPlatformList: get('/api/v2/user/animation/chapter/platform'), // 动态漫章节平台列表
    addAnComicChapter: post('/api/v2/user/animation/chapter/add'), // 增加动态漫章节
    editAnComicChapter: put('/api/v2/user/animation/chapter/info'), // 编辑动态漫章节

    getAnComicChapterList: get('/api/v2/user/animation/chapter/list'), // 获取用户动态漫章节列表
    getAnComicChapterInfo: get('/api/v2/user/animation/chapter/info'), // 查询动态漫章节信息
    editAnChapterOrder: put('/api/v2/user/animation/chapter/ordernum'), // 编辑动态漫章节排序
    displayAnComicChapter: put('/api/v2/user/animation/chapter/showstatus'), // 上/下架动态漫章节
    editAnComicChapterField: put('/api/v2/user/animation/chapter/updatefield'), // 漫画列表行内编辑

    getRelatedVideoList: get('/api/v2/user/animation/correlation/list'), // 获取用户动态漫相关视频列表
    addRelatedVideo: post('/api/v2/user/animation/correlation/add'), // 新增动态漫相关视频
    editRelatedVideo: put('/api/v2/user/animation/correlation/edit'), // 编辑动态漫相关视频
    displayAnComicVideo: put('/api/v2/user/animation/correlation/updateshowstatus'), // 动态漫相关视频上下架管理
    submitRelatedVideoAudit: put('/api/v2/user/animation/correlation/submit'), // 动态漫相关视频提交审核
    sortRelatedVideo: put('/api/v2/user/animation/correlation/ordernum'), // 动态漫相关视频排序
    getRelatedVideoInfo: get('/api/v2/user/animation/correlation/info'), // 获取动态漫相关视频详情

    /**
     * 漫画管理
     */
    addAndUpdateComic: post('/api/user/comic'), // 增加漫画，data={comicId,comicName,authorName,comicDesc,classIdList,comicCover34,comicCover169}
    editAndUpdateComic: put('/api/user/comic'), // 编辑漫画，data={comicId,comicName,authorName,comicDesc,classIdList,comicCover34,comicCover169}
    getComicList: get('/api/user/comic/list'), // 查询漫画列表，data={comicName,auditStatus,pageIndex,pageSize,showStatus}
    getComicInfo: get('/api/user/comic/info'), // 查询漫画详情，data={comicId}
    getLastChaptersn: get('/api/lastchaptersn'), // 查询章节列表，data={comicId}
    getAuthorList: get('/api/income/getauthorlist'), // 查询作者列表
    getPublicClassList: get('/api/user/comic/publicclass/list'), // 查询漫画类型列表
    getComicPlatformList: get('/api/user/comic/platform/list'), // 漫画发布平台列表
    getComicCheckedPlatformList: get('/api/user/comic/authorization/platform/list'), // 获取漫画已选择平台列表
    // 原来的发布管理已移到漫画审核列表
    // publishComic: put('/api/user/comic/authorization/platform'), // 发布漫画 data={comicId,comicName,authorName,updateList}
    submitaudit: put('/api/user/comic/submitaudit'), // 漫画提交审核 data={comicId}
    displayComic: put('/api/user/comic/display'), // 漫画上架/下架 data={comicIds}
    removeComic: remove('/api/user/comic'), // 删除漫画 data={comicId,comicName,authorName}
    /**
     * 章节管理
     */
    submitjob: post('/api2/autocut/submitjob'), // 自动裁图
    getjobprogress: get('/api2/autocut/getjobprogress'), // 获取自动裁图信息
    insertgreenImage: post('/api/user/comic/chapter/image/insertgreen'), // 将图片插入鉴黄表
    getGreenImageProcess: get('/api/user/comic/chapter/image/greenprocess'), // 获取鉴黄进度
    addComicChapter: post('/api/user/comic/chapter'), // 增加漫画章节，data={[chapterId,]comicId，chapterName，chapterSN，imageAddr，chapterPrice，chapterCover168，intro{},publishTask{}}
    editComicChapter: put('/api/user/comic/chapter'), // 编辑漫画章节，data={[chapterId,]comicId，chapterName，chapterSN，imageAddr，chapterPrice，chapterCover168，intro{},publishTask{}}
    getComicChapterInfo: get('/api/user/comic/chapter/info'), // 查询漫画章节信息，data={comicId，chapterId}
    getComicChapterList: get('/api/user/comic/chapter/list'), // 漫画章节列表，data={comicId}
    deleteComicChapter: remove('/api/user/comic/chapter'), // 删除漫画章节, data={comicId,chapterId}
    displayComicChapter: put('/api/user/comic/chapter/display'), // 上/下架漫画章节, data={comicId,chapterId，showStatus{0上架，1下架}}
    sortComicChapter: put('/api/user/comic/chapter/sort'), // 排序漫画章节, data={comicId,chapterId}
    editComicChapterField: put('/api/user/comic/chapter/field'), // 排序漫画章节-列表内编辑, data={comicId,chapterId}
    editChapterOrder: put('/api/user/comic/chapterorder'), // 调整章节排序，data={comicId,currChapterId,targetChapterId}
    decompressChapter: post('/api/user/comic/chapter/decompressfile'), // 解压章节, data={comicId,comicName,authorName,originFile,uploadFile}
    getDecompressProgress: get('/api/user/comic/chapter/decompressfile/progress'), // 解压进度, data={comicId,originFile}
    getDecompressChapter: get('/api/user/comic/chapter/decompresseddata'), // 解压进度, data={comicId,originFile, pageIndex, pageSize}
    removeDecompressChapter: remove('/api/user/comic/chapter/decompresseddata'), // 解压进度, data={comicId,originFile, pageIndex, pageSize}
    batchChapterPrice: put('/api/user/comic/chapter/price'), // 章节批量修改价格 data={comicId, chapters[], price, sourceObj{}}
    batchChapterCreatePrice: put('/api/user/comic/chapter/createprice'), // 章节批量修改创作价格

    /**
     * 管理中心-用户列表
     */
    getUserList: get('/api/admin/user/list'), // 查询用户列表, data={userName,enable,order}
    disableUserPermission: put('/api/admin/user/enable'), // 修改用户权限状态-启用、禁用, data={status,list:[{userI, userName}]}
    clearUserPermission: put('/api/admin/user/permission/clear'), // 清空用户权限状态-启用、禁用, data={list:[{userI, userName}]}
    editUserPermission: put('/api/admin/user/permission/add'), // 编辑用户权限, data={list:[{userI, userName}]}
    /**
     * 管理中心-权限设置
     */
    getAdminPermissionList: get('/api/admin/permission/list'), // 获取权限列表, data={name,enable,order,pageIndex,pageSize}
    getUserPermissionList: get('/api/admin/user/permission/list'), // 获取用户可选权限列表, data={name,enable,order,pageIndex,pageSize}
    addAdminPermission: post('/api/admin/permission'), // 新增权限, data={name,code,method,route,description,relationType,authType}
    editAdminPermission: put('/api/admin/permission'), // 修改权限, data={id,name,code,method,route,description,relationType,authType}
    removeAdminPermission: remove('/api/admin/permission/bycodelist'), // 删除权限, data={codeList:[]}
    enableAdminPermission: put('/api/admin/permission/enable'), // 删除权限, data={codeList:[]}
    /**
     * 管理中心-漫画审核
     */
    publishComic: put('/api/admin/comic/authorization/platform'), // 发布漫画 data={publishTime, status, comicId, comicName, authorName, platformId}
    getAuditComicList_Admin: get('/api/admin/comic/list'), // 获取漫画审核列表, data={comicName,statuses,pageIndex,pageSize}
    getChapterInfo_Admin: get('/api/admin/comic/chapter/preview'), // 管理员获取漫画章节信息, data={comicId,chapterId}
    getComicInfo_Admin: get('/api/admin/comic/preview'), // 管理员获取漫画信息, data={comicId}
    getAuditLog_Admin: get('/api/admin/comic/audit/log'), // 管理员获取审核日志, data={queryText,pageIndex,pageSize}
    refuseAudit_Admin: put('/api/admin/comic/auditrefuse'), // 管理员拒绝漫画审核, data={comicId}
    passAudit_Admin: put('/api/admin/comic/auditpass'), // 管理员通过漫画审核, data={comicId}
    getComicDiff_Admin: get('/api/admin/comic/diff'), // 管理员获取漫画差异数据, data={comicId}
    updateComicGrade: put('/api/admin/comic/comicgrade'), // 修改漫画等级, data={comicId,comicGrade}
    deferInfo: put('/api/admin/comic/deferinfo'), // 延迟备注, data={comicId,deferDesc}
    forceAuditPass: put('/api/admin/comic/forceauditpass'), // 强制通过, data={comicId}
    /**
     * 个人中心
     */
    resetPassWord: put('/api/user/personal/resetpassword'), // 重置密码
    getPersonalMessageList: get('/api/user/personal/message/list'), // 我的消息列表, data={pageIndex, pageSize}
    deletePersonalMessage: remove('/api/user/personal/messages'), // 删除我的消息, data={list:[id]}
    getOperatelogList: get('/api/user/personal/operatelog/list'), // 查询操作日志, data={queryText,logType,action,queryAllFlag,order,startTime,endTime,pageIndex,pageSize}
    markMessageRead: put('/api/user/personal/messages/markread'), // 设置消息已读, data={status,list:[id]}
    getUnreadCount: get('/api/user/personal/message/countunread'), // 获取未读消息数量, data={status,list:[id]}

    /**
     * 分成管理
     */
    getIncomeDetailList: get('/api/income/getincomedetail'), // 获取付费收入明细
    getRaiseIncomeDetailList: get('/api/income/getraiseincomedetail'), // 获取供养收入明细
    getAllIncome: get('/api/income/getallincome'), // 获取月总收入
    getIncomeProductList: get('/api/income/getproductlist'), // 获取统计产品线

    /**
     * 标签管理
     */
    addPubliccategory: post('/api/publiccategory/category'), // 新增标签分类, data={status,list:[id]}
    getPubliccategoryList: get('/api/publiccategory/query'), // 获取标签分类列表, data={status,list:[id]}
    setPubliccategoryStatus: put('/api/publiccategory/status'), // 修改标签分类状态, data={status,list:[id]}
    updatePubliccategory: put('/api/publiccategory/category'), // 修改标签分类, data={status,list:[id]}
    resortPubliccategory: put('/api/publiccategory/resort'), // 标签分类排序, data={status,list:[id]}
    affectcomiccountByCategory: get('/api/publiccategory/affectcomiccount'), // 标签分类涉及的漫画数量, data={status,list:[id]}

    addPublictags: post('/api/publictags/tag'), // 新增指定标签分类下标签, data={status,list:[id]}
    updatePublictags: put('/api/publictags/tag'), // 修改指定标签分类下标签, data={status,list:[id]}
    getPublictagsList: get('/api/publiccategory/tags'), // 查询指定分类下的标签列表, data={status,list:[id]}
    setPublictagsStatus: put('/api/publictags/status'), // 修改标签状态, data={status,list:[id]}
    resortPublictags: put('/api/publictags/resort'), // 排序标签, data={status,list:[id]}
    affectcomiccountByTag: get('/api/publictags/affectcomiccount'), // 标签涉及的漫画数量, data={status,list:[id]}

    getComictags: get('/api/publictags/comictags'), // 获取特定漫画的标签, data={status,list:[id]}
    saveComictags: post('/api/publictags/comictags'), // 保存特定漫画的标签, data={status,list:[id]}


      /**
     * 账号管理-账号列表
     */
      getcpconfiglist: get('/api/cp/getcpconfiglist'), // 查询账号列表参数 page  size  user_name （user_name 为cp名称 用户搜索 非必传）
      getcplist: get('/api/cp/getcplist'), // 查询cp列表参数，下拉选项
      addoredit: post('/api/cp/addoredit'), // 修改cp结算时间-参数  user_id  开始时间start_time


};
