<template>
  <el-dialog @close="handleClose" :close-on-click-modal="false" custom-class="dialog-pwd" title="修改密码" :visible.sync="isShow" append-to-body width="30%">
    <el-form :model="form" label-width="100px" ref="pwdForm" :rules="rules">
        <el-form-item label-width="0">
            <el-alert show-icon title="建议使用6-16位大小写英文字母+数字的密码，提高安全性" :closable="false"></el-alert>
        </el-form-item>
      <el-form-item label="输入原密码" prop="originPassward">
        <el-input maxlength="20" type="password" v-model="form.originPassward" autocomplete="off" placeholder="请输入原密码"></el-input>
      </el-form-item>
      <el-form-item label="输入新密码" prop="passward">
        <el-input maxlength="20" type="password" v-model="form.passward" autocomplete="off" placeholder="请输6 - 16位，区分大小写"></el-input>
        <div class="pwd-strength-box">
            <span>密码强度:&nbsp;</span>
            <el-progress :stroke-width="10" :show-text="false" :percentage="pwdStrength.percentage" :status="pwdStrength.status"></el-progress>
        </div>
      </el-form-item>
      <el-form-item label="输入新密码" prop="passward2">
        <el-input maxlength="20" type="password" v-model="form.passward2" autocomplete="off" placeholder="请再次输入确认密码"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleModPwd">确认修改</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === this.form.originPassward) {
                callback(new Error('新密码与原密码一致，请重新设置'));
            } else {
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value !== this.form.passward) {
                callback(new Error('新密码前后两次输入不一致，请重新设置'));
            } else {
                callback();
            }
        };
        return {
            isShow: false,
            form: {
                originPassward: '',
                passward: '',
                passward2: ''
            },
            rules: {
                originPassward: [{
                    required: true,
                    message: '请输入原密码'
                }],
                passward: [{
                    required: true,
                    message: '请输入新密码'
                },
                {
                    validator: validatePass
                }],
                passward2: [{
                    required: true,
                    message: '请输入新密码'
                },
                {
                    validator: validatePass2
                }]
            },
            pwdStrength: {
                percentage: 0,
                status: 'exception'
            }
        };
    },
    watch: {
        'form.passward'(val, oldValue) {
            let lv = 0;
            const regNum = /([0-9])+/;
            const regA = /([A-Z])+/;
            const rega = /([^A-Z0-9])+/;
            if (val.length < 6) {
                this.pwdStrength = {
                    percentage: 33,
                    status: 'exception'
                };
                return;
            }
            if (val.match(regNum)) {
                lv++;
            }
            if (val.match(regA)) {
                lv++;
            }
            if (val.match(rega)) {
                lv++;
            }
            if (lv === 1) {
                this.pwdStrength = {
                    percentage: 33,
                    status: 'exception'
                };
            } else if (lv === 2) {
                this.pwdStrength = {
                    percentage: 66,
                    status: 'warning'
                };
            } else if (lv === 3) {
                this.pwdStrength = {
                    percentage: 100,
                    status: 'success'
                };
            }
        }
    },
    created() {
        this.$on('EV_SHOW', this.handleShow);
    },
    methods: {
        // 重置表单
        resetFormFields(formName) {
            this.$refs[formName].resetFields();
        },
        handleClose() {
            this.resetFormFields('pwdForm');
        },
        handleShow() {
            this.isShow = true;
        },
        async handleModPwd() {
            this.$refs['pwdForm'].validate(valid => {
                if (!valid) return false;
                this.$api('resetPassWord', { newPassward: this.form.passward2, originPassward: this.form.originPassward }).then(res => {
                    this.$message.success('修改成功！');
                    this.isShow = false;
                    if (this.$route.path === '/login') {
                        this.$router.replace({ name: 'welcome' });
                    }
                });
            });
        }
    }
};
</script>
<style lang="scss">
.dialog-pwd {
    border-radius: 4px;
    .el-dialog__body {
        padding-bottom: 15px;
        padding-top: 15px;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .pwd-strength-box {
        display: flex;
        align-items: center;
        color: #909399;
        .el-progress {
            flex: auto;
        }
        .el-progress-bar__outer, .el-progress-bar__inner{
            border-radius: 2px;
        }
    }
}
</style>
