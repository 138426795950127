<template>
    <el-dialog :title="title" :visible.sync="newVisible" :width="width" :custom-class="heightFull ? 'cropper-dialog height-full' : 'cropper-dialog'" :top="heightFull ? '30px' : '20vh'" @close="$emit('close')" @closed="$emit('closed')" @open="open" @scroll.native.prevent>
        <div class="cropper-dialog-content" ref="customContent">
            <div class="cropper-dialog-box flex-row-between">
                <div class="cropper-dialog-main">
                    <img id="cropper" ref="cropperImage" :src="image">
                </div>
                <div class="cropper-dialog-aside upload-bg" :style="{width: options.minPreviewWidth + 'px', height: options.minPreviewHeight + 'px'}">
                    <div class="img-preview" :style="{width: options.minPreviewWidth + 'px', height: options.minPreviewHeight + 'px'}"></div>
                </div>
            </div>
        </div>
        <span slot="footer">
            <el-button @click="newVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-05 11:44:35
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-13 11:27:23
 * @Description: 图片剪切
 * @Company: 成都二次元动漫
 */
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

const cropperOptions = {
    preview: '.img-preview',
    dragMode: 'move',
    cropBoxMovable: false, // 裁剪盒子不能移动
    cropBoxResizable: false, // 裁剪盒子不能缩放
    toggleDragModeOnDblclick: false,
    autoCropArea: 1,
    minContainerHeight: 320,
    minContainerWidth: 320
};
export default {
    props: {
        // 高度是否延伸
        image: String,
        heightFull: { type: Boolean, default: false },
        visible: { type: Boolean, default: false },
        title: { type: String, default: '' },
        width: { type: String, default: '560px' },
        options: { type: Object, default: () => {} } // 配置
    },
    data() {
        return {};
    },
    computed: {
        newVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            }
        }
    },
    mounted() {
        // 阻止滚动穿透
        this.$el.addEventListener('wheel', event => {
            event.stopPropagation();
        });
    },
    updated() {
        this.initCropper();
    },
    methods: {
        // 初始化剪切实例
        initCropper() {
            const option = { ...cropperOptions, ...this.options };
            if (this.cropper) {
                this.cropper.replace(this.image, false);
                this.cropper.setAspectRatio(option.aspectRatio);
            } else {
                this.cropper = new Cropper(document.getElementById('cropper'), option);
            }
        },
        // 确定裁剪
        confirm() {
            this.cropper.getCroppedCanvas().toBlob(blob => {
                this.$emit('getCropperImage', blob);
            });
        },
        close(obj) {
            this.$emit('update:visible', false);
            this.$emit('close');
        },
        open() {
            this.$nextTick(() => {
                this.$refs.customContent.scrollTop = 0;
            });
            this.$emit('open');
        }
    }
};
</script>
<style lang="scss">
    .cropper-dialog{
        border-radius: 10px;
        &.height-full{
            height: calc(100% - 80px);
        }
        display: flex;
        flex-flow: column nowrap;
        .el-dialog__header{
            box-sizing: border-box;
            height: 50px;
        }
        .el-dialog__body{
            box-sizing: border-box;
            padding: 20px;
            // flex-grow: 1;
            overflow: hidden;
            display: flex;
            flex-flow: column nowrap;
            .cropper-dialog-content{
                flex-grow: 1;
                overflow-x: hidden;
                overflow-y: auto;
                border: none !important;
            }
        }
        .el-dialog__footer{
            text-align: center;
            height: 60px;
        }
        .img-preview{
            overflow: hidden;
        }
        &-box{
            width: 100%;
            height: 320px;
            padding: 0 0 20px;
            align-items: flex-start;
        }
        &-main{
            width: 320px;
            height: 320px;
        }
        &-aside{
            width: 180px;
            height: 240px;
            background: #f5f5f5;
        }
    }
</style>
