const Grades = [
    { value: 1, label: '1级（杂志漫画标准）', short: '1级' },
    { value: 2, label: '2级（国内网漫标准）', short: '2级' },
    { value: 3, label: '3级（国外网漫标准）', short: '3级' }
];

const GradesAll = [{ value: 0, label: '未配置等级' }, ...Grades];

export {
    Grades,
    GradesAll
};
