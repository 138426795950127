import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import { beforeEach } from './helper';

Vue.use(Router);

const router = new Router(routes);

router.beforeEach(beforeEach);

export default router;
