/*
 * @Author: daipeng
 * @Date: 2018-09-07 12:07:33
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-29 11:20:24
 * @Description: pagination mixins
 * @Company: 成都二次元动漫
 */

import { paginationPlus } from '@/components';
export default {
    data() {
        return {
            pagination: {
                pageIndex: 1,
                pageSize: 20,
                total: 0
            }
        };
    },
    components: {
        paginationPlus
    }
};
