/*
 * @Author: daipeng
 * @Date: 2018-09-19 20:32:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-30 10:53:02
 * @Description: 漫画信息mixin
 * @Company: 成都二次元动漫
 */
import { comicCard, auditComicCard } from '@/components/comic';
import { mapMutations } from 'vuex';

export default {
    data() {
        return {
            publicClass: [],
            comicData: {
                comicId: null,
                comicName: '',
                authorName: '',
                comicDesc: '',
                comicCover34: '', // 封面图1 3:4
                comicCover43: '', // 封面图1 4:3
                comicCover169: '', // 封面图2 16:9
                classIdList: '',
                serial: null, // 6 连载状态
                comicType: null, // 13 漫画类型
                audience: null, // 14 受众
                theme: [], // 17 题材
                platformList: [], // 漫画发布平台
                agreement: false,
                comicGrade: null, // 漫画等级
                chapterPrice: null // 漫画价格
            },
            comicDataClone: {},
            comicDataRules: {
                comicName: [
                    { pattern: /\S+/, required: true, type: 'string', message: '请输入漫画名称，无需填写《》或其他符号', trigger: 'blur' },
                    { min: 1, max: 20, pattern: /\S+/, required: true, type: 'string', message: '漫画名称最少1个字符，最多20个字符', trigger: 'change' }
                ],
                authorName: [
                    { required: true, message: '作者不能为空，多个作者间用逗号隔开', trigger: 'blur' }
                ],
                comicGrade: [
                    { required: true, message: '请选择漫画等级', trigger: 'change' }
                ],
                comicDesc: [
                    { required: true, pattern: /\S+/, type: 'string', message: '请输入关于作品的描述', trigger: 'blur' },
                    { min: 1, max: 200, pattern: /\S+/, type: 'string', message: '漫画描述最多200个字', trigger: 'change' }
                ],
                comicCover34: [
                    { required: true, type: 'string', pattern: /\S+/, message: '封面地址不能为空' }
                ],
                comicCover43: [
                    { required: true, type: 'string', pattern: /\S+/, message: '封面地址不能为空' }
                ],
                comicCover169: [
                    { required: true, type: 'string', pattern: /\S+/, message: '封面地址不能为空' }
                ],
                serial: [
                    { required: true, message: '请选择连载状态', trigger: 'change' }
                ],
                comicType: [
                    { required: true, message: '请选择漫画类型', trigger: 'change' }
                ],
                audience: [
                    { required: true, message: '请选择当前漫画受众', trigger: 'change' }
                ],
                createPrice: [
                    { required: true, message: '创作价格不能为空', trigger: 'blur' }
                ],
                comicFeature: [
                    { required: true, message: '漫画看点不能为空', trigger: 'blur' },
                    { min: 12, max: 18, message: '字数不足或超出12-18字，请检查后输入', trigger: 'blur' }
                ],
                updateCycle: [
                    { required: true, message: '更新规律不能为空', trigger: 'blur' },
                    { min: 1, max: 60, message: '字数不足或超出1-60字，请检查后输入', trigger: 'blur' }
                ],
                fontsName: [
                    { required: true, message: '全文使用字体不能为空', trigger: 'blur' },
                    { min: 1, max: 50, message: '字数不足或超出1-50字，请检查后输入', trigger: 'blur' }
                ],
                fontsCopyright: [
                    { required: true, message: '请选择字体是否有版权', trigger: 'change' }
                ],
                theme: [
                    { type: 'array', required: true, message: '请选择当前漫画的题材', trigger: 'blur' },
                    { type: 'array', max: 3, message: '最多选择3个当前漫画的题材', trigger: 'change' }
                ],
                platformList: [
                    { type: 'array', required: true, message: '请选择当前漫画发布平台', trigger: 'blur' }
                ],
                chapterPrice: [
                    { required: true, message: '价格不能为空', trigger: 'blur' }
                ],
            }
        };
    },
    methods: {
        ...mapMutations('comic', [ 'setTagList' ]),
        // 组装漫画数据
        formatComicData(publicClass, comicData) {
            this.publicClass = publicClass[0].child;
            if (!this.$utils.isEmptyObject(comicData)) {
                this.comicData = {
                    ...this.comicData,
                    ...comicData,
                    ...this.$utils.formatPublicClass(this.publicClass, comicData.classIdlist.split(',')),
                    classIdList: comicData.classIdlist,
                    sourceObj: this.$utils.cloneDeep(comicData)
                };
                this.setTagList(this.comicData.tag_list);
                this.comicDataClone = this.$utils.cloneDeep(this.comicData);
            }
        },
        // 请求漫画分分类信息、漫画数据
        getAllData(list) {
            Promise.all(list).then(res => {
                const [publicClass, comicData = {}] = res;
                this.formatComicData(publicClass, comicData);
            });
        },
        // 获取漫画类型
        getPublicClass() {
            return this.$api('getPublicClassList').then(({ data }) => data);
        },
        // 获取漫画内容
        getComicData(comicId) {
            return this.$api('getComicInfo', { comicId }).then(({ data }) => {
                if (this.$utils.isEmptyObject(data)) {
                    this.$message.error('当前漫画不存在！');
                    return this.$router.back();
                } else return data;
            });
        }
    },
    components: {
        comicCard,
        auditComicCard
    }
};
