<template>
    <div class="login-up">
        login up
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-03 13:29:59
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-03 13:42:28
 * @Description: 客服查询系统-注册
 * @Company: 成都二次元动漫
 */
export default {
    data () {
        return {

        };
    }
};
</script>
<style lang="scss" scoped>

</style>
