/*
 * @Author: daipeng
 * @Date: 2018-09-19 19:29:18
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-22 17:55:46
 * @Description: 图片上传mixin
 * @Company: 成都二次元动漫
 */
import { isBlob, isFormData, isFileData } from '@/libs/utils';
import OSS from 'ali-oss';
import ObsClient from 'esdk-obs-browserjs';
export default {
    data() {
        return {
            tokenData: {},
            uploadData: null,
            tempCheckpoint: ''
        };
    },
    methods: {
        // 上传图片方法
        async uploadHandle(data, options = {}) {
            if (!data.file) return console.error('file为必填');
            if (!(isBlob(data.file) || isFormData(data.file) || isFileData(data.file))) {
                return Promise.reject(new Error('file type is wronge'));
            }
            const uploadData = await this.getUploadData(data.file);
            const { obsClient, filePath,link, fileObj,bucket } = uploadData;
            return new Promise((resolve, reject) => {
                obsClient.putObject(
                  {
                      Bucket: bucket,
                      Key: filePath, // 存储在obs的文件。可写成file/name即会生成file文件夹name文件
                      SourceFile: fileObj // 文件上传使用sourceFile文本上传采用Body
                      // ACL: this.obsClient.enums.AclPublicRead // 如果设置ACL即为公共访问。不需要认证如果不设置此项ACL，则需要先通过文件下载在进行路径访问
                  },
                   (err, result)=> {
                      if (err) {
                          console.error('Error-->' + err);
                      } else {
                          console.log('Status-->' + result.CommonMsg.Status);
                          // that.$emit('imges', link);
                          resolve({name:'/'+filePath});
                        
                      }
                  }
              );
            })
            // const { client, filePath, fileObj } = uploadData;
            // let res = await client.multipartUpload(filePath, fileObj, {
            //     progress: function (p, checkpoint) {
            //         this.tempCheckpoint = checkpoint;
            //     }
            // });
            // return res;
        },
        // 组织上传数据
        async getUploadData(fileObj) {
            console.log(323123)
            const { bucket, filePath, link, server,credential:{access, secret, securitytoken} } = this.tokenData;

            let obsClient = new ObsClient({
                access_key_id: access, //
                secret_access_key: secret,
                security_token: securitytoken,
                server //     "server": "",
            });
            return { obsClient, filePath: filePath, link, fileObj ,bucket};
            // const { region, bucket, token: { AccessKeySecret, AccessKeyId, SecurityToken }, filepath, link } = this.tokenData;
            // const client = new OSS({
            //     region,
            //     accessKeyId: AccessKeyId,
            //     accessKeySecret: AccessKeySecret,
            //     stsToken: SecurityToken,
            //     bucket
            // });
            // return { client, filePath: filepath, link, fileObj };
        },
        // 获取封面上传token
        getRelatedVideoCoverToken({ correlation_id, filename, type }) {
            return this.$api('getRelatedVideoCoverToken', {correlation_id, filename, type}).then(res => (this.tokenData = { ...this.tokenData, ...res.data }));
        }
    }
};
