import { cloneDeep } from '@/libs/utils';

const defaultState = {
    tagList: [],
    refresh: 0
};

export default {
    namespaced: true,
    state: cloneDeep(defaultState),
    mutations: {
        setTagList(state, payload) {
            state.refresh = Date.now();
            state.tagList = [...payload];
        }
    }
};
