import app from '@/store/modules/app';
import comic from '@/store/modules/comic';

export const stores = {
    state: {},
    mutations: {},
    modules: {
        app,
        comic
    }
};

export const storeCreator = Vuex => {
    return new Vuex.Store(stores);
};

export default storeCreator;
