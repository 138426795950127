<template>
    <router-view></router-view>
</template>
<script>
export default {
    data () {
        return {};
    },
    created() {
        this.isLogin();
    },
    methods: {
        isLogin() {
            this.$api('islogin').then(({ status, data }) => {
                if (status === 0) {
                    this.$store.commit('app/setUserInfo', data);
                    this.refreshRouter();
                }
            }).catch(() => {
                if (window.location.pathname !== '/login') this.$router.replace({ path: '/login' });
            });
        },
        // 控制页面刷新路由跳转
        refreshRouter() {
            const { path } = this.$route;
            const { pathname } = window.location;
            if (path === '/' && pathname === '/') {
                this.$router.replace({ path: '/main' });
            }
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
