<template>
    <el-tooltip :content="isFull ? '退出全屏' : '全屏'" v-if="showFullScreenBtn">
        <i :class="isFull ? 'el-icon-exit-fullscreen' : 'el-icon-fullscreen'" @click="screenChangeHandle" />
    </el-tooltip>
</template>
<script>
export default {
    name: 'fullScreen',
    data() {
        return {
            isFull: false,
            showFullScreenBtn: window.navigator.userAgent.indexOf('MSIE') < 0
        };
    },
    created() {
        this.isFull = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        document.addEventListener('fullscreenchange', () => {
            this.$emit('input', !this.value);
            this.$emit('on-change', !this.value);
        });
        document.addEventListener('mozfullscreenchange', () => {
            this.$emit('input', !this.value);
            this.$emit('on-change', !this.value);
        });
        document.addEventListener('webkitfullscreenchange', () => {
            this.$emit('input', !this.value);
            this.$emit('on-change', !this.value);
        });
        document.addEventListener('msfullscreenchange', () => {
            this.$emit('input', !this.value);
            this.$emit('on-change', !this.value);
        });
        this.$emit('input', this.isFull);
    },
    methods: {
        // 屏幕操作
        screenChangeHandle() {
            if (this.isFull) this.exitFullscreenHandle();
            else this.fullscreenHandle();
            this.isFull = !this.isFull;
        },
        // 全屏操作
        fullscreenHandle() {
            if (document.body.requestFullscreen) document.body.requestFullscreen();
            else if (document.body.mozRequestFullScreen) document.body.mozRequestFullScreen();
            else if (document.body.webkitRequestFullScreen) document.body.webkitRequestFullScreen();
            else if (document.body.msRequestFullscreen) document.body.msRequestFullscreen();
        },
        // 退出全屏操作
        exitFullscreenHandle() {
            if (document.exitFullscreen) document.exitFullscreen();
            else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
            else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
            else if (document.msExitFullscreen) document.msExitFullscreen();
        }
    }
};
</script>
