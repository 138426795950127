/*
 * @Author: daipeng
 * @Date: 2018-09-05 11:52:13
 * @LastEditors: VSCode
 * @LastEditTime: 2018-11-30 17:10:37
 * @Description: 自带组件型的dialog-mixins(@/components/dialog-plus)
 * @Company: 成都二次元动漫
 *
 * @usage 使用minixs方法引入，其中已经附加dailog-plus组件，通过changeDialog修改状态, dialogCloseHandle关闭清理数据
 */

import { dialogPlus } from '@/components';
export default {
    data() {
        return {
            dialogData: {
                visible: false,
                title: '',
                type: 0, // 0 查看权限详情，1 新增，2 编辑
                data: {}
            }
        };
    },
    created() {
        // 默认状态下执行清空数据方法，如果需要额外添加句柄（如，取消按钮），可直接使用dialogCloseHandle
        this.$emit('closed', this.dialogCloseHandle);
    },
    methods: {
        /**
         * 修改自定义dialog状态，包括数据
         *
         * @param {*} [visible=this.dialogData.visible] 默认false
         * @param {*} [title=this.dialogData.title]     名称，默认''
         * @param {*} [type=this.dialogData.type]       0 查看权限详情，1 新增，2 编辑，默认0
         * @param {*} [data=this.dialogData.data]       展示数据，默认{}
         */
        changeDialog(visible = this.dialogData.visible, title = this.dialogData.title, type = this.dialogData.type, data = this.dialogData.data, isClone = true) {
            this.dialogData = { visible, title, type, data: isClone ? this.$utils.cloneDeep(data) : data };
        },
        // 关闭dailog
        dialogCloseHandle() {
            this.dialogData = this.$options.data().dialogData;
        }
    },
    components: {
        dialogPlus
    }
};
