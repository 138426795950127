<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-04 15:49:07
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-04 17:51:28
 * @Description: 包装table-column，避免重复些标签结构
 * @Company: 成都二次元动漫
 */
import { TableColumn } from 'element-ui';
// column渲染方法map
const renderCell = {
    slots: function(h, data) {
        const that = this;
        let renderCell = function(h, data) {
            return that.renderCell ? that.renderCell(data) : ''; // this.renderCell是渲染column内的template内容的
        };
        return <div class="cell">{renderCell(h, data)}</div>;
    }
};

export default {
    extends: TableColumn,
    props: {
        prop: {
            type: [String, Number]
        },
        align: {
            type: String,
            default: 'center'
        },
        cellType: {
            type: String,
            validator: value => {
                let valid = ['text', 'input', 'slots'].includes(value);
                !valid && console.error(`columnPlus组件不适配${value}类型，请使用元素el-table-column组件`);
                return valid;
            }
        },
        renderCell: Function
    },
    created() {
        if (renderCell[this.cellType]) {
            this.columnConfig.renderCell = renderCell[this.cellType].bind(this);
        }
    }
};
</script>
