<template>
    <el-header class="main-header">
        <div class="main-header-left">
            <i class="el-icon-menu" @click="collapseHanddle" :style="{transform:`rotateZ(${isCollapse ? '-90' : '0'}deg)`}"></i>
            <el-breadcrumb class="main-header-breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="(item, index) in breadcrumb" :key="item + index" :replace="true" :to="item.name && { name: item.name, query: item.query || {} }">{{item.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main-header-right">
            <fullScreen></fullScreen>
            <el-dropdown @command="handleClickUserDropdown">
                <a href="###">
                    <span class="main-header-name">{{ userInfo.name }}</span>
                    <i class="el-icon-arrow-down el-icon-right"></i>
                </a>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled common="ownSpace">个人中心</el-dropdown-item>
                    <el-dropdown-item command="modpwd">修改密码</el-dropdown-item>
                    <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="main-header-avatar">
                <img src="/static/images/avatar_default.jpeg" :alt="userInfo.name">
            </div>
        </div>
        <dialogPwd ref="dialogPwd"></dialogPwd>
    </el-header>
</template>
<script>
import { mapState } from 'vuex';
import fullScreen from '@/components/fullScreen';
import dialogPwd from '@/components/dialogPwd';

export default {
    name: 'customHeader',
    props: {
        isCollapse: Boolean
    },
    computed: {
        ...mapState('app', ['breadcrumb']),
        ...mapState('app', ['userInfo'])
    },
    methods: {
        // 按钮触发
        handleClickUserDropdown(name) {
            if (name === 'modpwd') {
                return this.handleModPwd();
            }
            if (name === 'ownSpace') {
            } else if (name === 'loginout') this.logoutHandle();
        },
        // 修改密码
        handleModPwd() {
            this.$refs.dialogPwd.$emit('EV_SHOW');
        },
        // 退出登录
        logoutHandle() {
            this.$api('logout').then(res => {
                this.$store.commit('app/resetState');
                this.$router.replace({ path: '/login' });
            });
        },
        // 控制导航栏展开收缩
        collapseHanddle() {
            this.$emit('update:isCollapse', !this.isCollapse);
        }
    },
    components: {
        fullScreen,
        dialogPwd
    }
};
</script>
<style lang="scss">
    .main-header{
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        box-shadow: 0 2px 1px 1px rgba(100, 100, 100, 0.1);
        transition: padding 0.3s;
        z-index: 1;
        .el-icon-menu{
            cursor: pointer;
            color: #a0cfff;
            margin-right: 40px;
        }
        &-left{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex-grow: 1;
        }
        &-right{
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
        }
        &-avatar{
            width: 32px;
            height: 32px;
            margin-left: 10px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .el-dropdown-selfdefine{
            margin-left: 40px;
        }
        &-name{
            color: #409EFF;;
        }
        .el-icon-arrow-down{
            color: #ccc;
        }
    }
</style>
