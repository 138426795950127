<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-04 18:46:21
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-15 18:29:52
 * @Description: 封装elemnent-ui的分页组件
 * @Company: 成都二次元动漫
 */

import { Pagination } from 'element-ui';

export default {
    extends: Pagination,
    props: {
        currentPage: {
            type: Number,
            default: 1
        },
        pageSizes: {
            type: Array,
            default: () => [10, 20, 30, 40]
        },
        layout: {
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        total: Number,
        // 用于在分页操作是执行需要的回调
        callback: Function
    },
    data() {
        return {
            prevPage: 1
        };
    },
    created() {
        this.$on('size-change', this.sizeChangeHandle);
        this.$on('current-change', this.currentChangeHandle);
        this.$on('prev-click', this.prevHandle);
        this.$on('next-click', this.nextHandle);
    },
    methods: {
        // 改变每页展示数量
        sizeChangeHandle(size) {
            this.emitCallback();
            this.$emit('update:pageSize', size);
        },
        // 当前页改变
        currentChangeHandle(page) {
            // 将所有的分页操作收口到一个句柄，方便执行列表刷新
            this.emitCallback();
            this.$emit('update:currentPage', page);
        },
        // 上一页
        prevHandle() {
            this.prevPage = this.$utils.cloneDeep(this.currentPage);
        },
        // 下一页
        nextHandle() {
            this.prevPage = this.$utils.cloneDeep(this.currentPage);
        },
        // 通知回调
        emitCallback() {
            this.$emit('callback', { page: this.currentPage, size: this.pageSizes, prevPage: this.prevPage });
        }
    }
};
</script>
