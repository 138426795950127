/*
 * @Author: daipeng
 * @Date: 2018-09-03 18:04:15
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-09 15:10:29
 * @Description: 输出请求方法
 * @Company: 成都二次元动漫
 */
import apisMap from '@/api/map';
import { filterEmptyString } from '@/libs/utils';

/**
 * 统一封装ajax入口
 *
 * @param {*} name      接口名称
 * @param {*} data      请求数据
 * @param {*} config    请求配置，非必填
 * @param {*} options   操作参数，非必填
 */
const apiHandle = (name, data, options = {}, config = {}, filter = true) => {
    if (filter) data = filterEmptyString(data);
    if (apisMap[name]) {
        return apisMap[name](data)(options)(config);
    } else {
        return false;
    }
};

export default apiHandle;
