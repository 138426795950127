/*
 * @Author: daipeng
 * @Date: 2018-09-19 14:05:29
 * @LastEditors: VSCode
 * @LastEditTime: 2019-04-26 16:11:49
 * @Description: 全局配置
 * @Company: 成都二次元动漫
 */
const VUE_APP_ENV = process.env.VUE_APP_ENV;
const isProduction = ['production'].includes(VUE_APP_ENV);
export default {
    acceptZip: 'zip,rar,z7',
    acceptImg: 'image/jpg,image/jpeg,image/png,image/gif',
    acceptVideo: '.MP4,.TS,.3GP,.MPG,.MPEG,.MPE,.DAT,.VOB,.ASF,.AVI,.WMV,.ASF,.FLV,.F4V,.RM,.RMVB,.MOV,.MKV,.M3U8,.DV,.M2T,.M4V,.MJ2,.MJPEG,.MTS,.OGG,.QT,.SWF,.WEBM',
    // cdn: 'https://cnmanhua.oss-cn-hangzhou.aliyuncs.com',
    // cdn: 'http://opensystem.321mh.com',
    cdn: isProduction ? 'http://image.cnmanhua.com' : 'http://cnmanhua.gougouwu.com'
    // cdn: 'http://image.cnmanhua.com'
}
