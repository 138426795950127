<template>
    <div class="custom-menu" v-scrollbar>
        <el-menu
            :style="{width:isCollapse?'64px':'200px'}"
            :collapse="isCollapse"
            :default-active="currentPath"
            :default-openeds="defaultOpened"
            :unique-opened="true"
            :router="true"
            @open="openHandle"
            @close="closeHandle"
            @select="selectHandle"
            background-color="#0d4074"
            text-color="#eee"
            active-text-color="#ffee66"
        >
            <template v-for="item in menuList">
                <template v-if="item.isMenu">
                    <el-submenu v-if="item.children" :key="item.name" :index="item.path">
                        <template slot="title">
                            <i class="custom-menu-icon" :class="item.icon" v-if="item.icon"></i>
                            <span class="title">{{item.title}}</span>
                        </template>
                        <el-menu-item v-if="v.isMenu" v-for="(v,i) in item.children" :key="item.name +'-' + i" :index="item.path+'/'+v.path">{{v.title}}</el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :key="item.name" :index="item.path">
                        <i class="custom-menu-icon" :class="item.icon" v-if="item.icon"></i>
                        <span class="title">{{item.title}}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    props: {
        isCollapse: Boolean
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', {
            menuList: state => state.menuList
        }),
        defaultOpened() {
            const matched = this.$route.matched;
            const len = matched.length;
            if (len === 2) return [matched[1].path];
            else if (len >= 3) return [matched[1].path, matched[2].path];
        },
        currentPath(menu) {
            const matched = this.$route.matched;
            const len = matched.length;
            if (len === 2) return matched[1].path;
            else if (len >= 3) {
                // 特殊处理
                const path2 = matched[2].path;
                if (['/main/adminCenter/auditComicInfo'].includes(path2)) return '/main/adminCenter/auditComic';
                if (['/main/comic/editComic', '/main/comic/editComicChapter'].includes(path2)) return '/main/comic/myComic';
                return path2;
            }
        }
    },
    created() {
        // this.currentPath = this.$route.path;
    },
    methods: {
        // menu展开
        openHandle() {
        },
        // menu关闭
        closeHandle() {
        },
        // menu选中
        selectHandle() {
        }
    }
};
</script>
<style lang="scss">
    .custom-menu{
        height: calc(100% - 64px);
        overflow: hidden;
        position: relative;
        transition: width 0.5s ease;
        .el-menu{
            border-right: none;
            transition: width 0.5s ease;
            .el-submenu__title{
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                span.title{
                    font-size: 14px;
                    margin-left: 5px;
                }
            }
        }
        &-icon{
            font-size: 18px;
        }
    }
</style>
