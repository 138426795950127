/*
 * @Author: daipeng
 * @Date: 2019-04-01 14:49:27
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-12 18:11:34
 * @Description:
 */
import querystring from 'querystring';
import { Object } from 'core-js';
import { MessageBox } from 'element-ui';
import http from '@/api';
const path = require('path');

export { default as cloneDeep } from 'lodash/cloneDeep';

export const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 };
    }
};

// 编码url
export const queryEscape = querystring.escape;

// 解码url
export const queryUnescape = querystring.unparse;

// 解析url
export const queryParse = querystring.parse;

// 序列化url
export const queryStringify = querystring.stringify;

// 检测数据类型
export const objectToString = type => Object.prototype.toString.call(type);

/**
 * 校验数据类型
*/
export const isArray = arr => objectToString(arr) === '[object Array]';
export const isFunction = fun => objectToString(fun) === '[object Function]';
export const isNull = n => objectToString(n) === '[object Null]';
export const isUndefined = u => objectToString(u) === '[object Undefined]';
export const isBoolean = b => objectToString(b) === '[object Boolean]';
export const isObject = o => objectToString(o) === '[object Object]';
export const isString = str => objectToString(str) === '[object String]';
export const isBlob = blob => objectToString(blob) === '[object Blob]';
export const isFormData = formdata => objectToString(formdata) === '[object FormData]';
export const isFileData = file => objectToString(file) === '[object File]';
export const isImageObj = image => objectToString(image) === '[object Image]';
export const isPromise = fn => objectToString(fn) === '[object Promise]';
export const isNumber = num => (objectToString(num) === '[object Number]' ? !Number.isNaN(num) : false);
export const isEmptyObject = obj => {
    if (JSON.stringify(obj) === '{}') return true;
    else return false;
};
// 汉字正则
export const chineseRegExp = /^[\u2E80-\u9FFF]+$/;
// 是否为汉字
export const isChinese = str => chineseRegExp.test(str);
/**
 * 替换url中的参数，如
 *      /user/:userId  ===>  /user/1
 *
 * @param {string} url
 * @param {object} params
 * @returns url
 */
export const replaceUrl = (url, params) => {
    if (!isString(url) || !url || !isObject(params)) return;
    return url.replace(/\/:(\w+)/g, (m, n) => {
        return `/${params[n]}`;
    });
};

/**
 * 时间格式化
 *
 * @param {*} time
 * @param {string} [fmt='yyyy.MM.dd hh:mm:ss']
 * @returns
 */
export const timeFormat = (time, fmt = 'yyyy.MM.dd hh:mm:ss') => {
    let objectDate;
    if (time instanceof Date) objectDate = time;
    else objectDate = new Date(time);

    let o = {
        'M+': objectDate.getMonth() + 1, // 月份
        'd+': objectDate.getDate(), // 日
        'h+': objectDate.getHours(), // 小时
        'm+': objectDate.getMinutes(), // 分
        's+': objectDate.getSeconds(), // 秒
        'q+': Math.floor((objectDate.getMonth() + 3) / 3), // 季度
        'S': objectDate.getMilliseconds() // 毫秒
    };

    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (objectDate.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
    return fmt;
};

export const filterEmptyString = data => {
    if (isObject(data)) {
        Object.keys(data).forEach(key => {
            if (data[key] === '') data[key] = null;
            else if (isObject(data[key])) data[key] = filterEmptyString(data[key]);
        });
    }
    return data;
};

export const getClassId = (curClass, classidlist) => {
    let ids = [];
    let names = [];
    classidlist.forEach(item => {
        curClass.some(v => {
            if (v.id === Number(item)) {
                ids.push(v.id);
                names.push(v.name);
                return true;
            } else return false;
        });
    });
    return { ids, names };
};

/**
 * 格式化漫画类型
 *
 * @param {Array} publicClass
 * @param {Array} classIdList
 * @returns {Object}
 */
export const formatPublicClass = (publicClass, classIdList) => {
    const serialData = getClassId(publicClass[0].child, classIdList);
    const comicTypeData = getClassId(publicClass[1].child, classIdList);
    const audienceData = getClassId(publicClass[2].child, classIdList);
    const themeData = getClassId(publicClass[3].child, classIdList);
    return {
        // 状态
        serial: Number(serialData.ids.join()), // 6
        serialNames: serialData.names,
        // 类型
        comicType: Number(comicTypeData.ids.join()), // 13
        comicTypeNames: comicTypeData.names,
        // 受众
        audience: Number(audienceData.ids.join()), // 14
        audienceNames: audienceData.names,
        // 题材
        theme: themeData.ids.sort((a, b) => a > b), // 17
        themeNames: themeData.names
    };
};

/**
 * 计算页数
 *
 * @param {Number} pageSize
 * @param {Number} total
 * @returns {Number}
 */
export const pageTotal = (pageSize, total) => {
    return Math.ceil(total / pageSize);
};

/**
 * 数组互换位置
 *
 * @param {Array} arr
 * @param {Array} index1
 * @param {Array} index2
 * @returns {Array}
 */
export const swapArrayItems = function(arr, index1, index2) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0];
    return arr;
};
/**
 * 数组移动
 *
 * @param {Array} arr
 * @param {Array} index1
 * @param {Array} index2
 * @returns {Array}
 */
export const moveArrayItems = function(arr, newIndex, oldIndex) {
    const oldItem = JSON.parse(JSON.stringify(arr[oldIndex]));
    arr.splice(oldIndex, 1);
    arr.splice(newIndex, 0, oldItem);
    return arr;
};
/**
 * 定制confirm方法
 *
 * @param {*} title
 * @param {*} message
 * @param {*} loading
 * @param {*} options
 * @returns
 */
export const confirm = (title, message, loading, callback, options) => {
    return new Promise((resolve, reject) => {
        MessageBox.confirm(message, title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showCancelButton: true,
            center: true,
            closeOnClickModal: false,
            beforeClose: (action, instance, done) => {
                const closeHandle = () => {
                    instance.confirmButtonLoading = false;
                    done();
                };

                if (action === 'confirm') {
                    instance.confirmButtonLoading = true;
                    loading && (instance.confirmButtonText = loading);
                    closeHandle();
                    if (isPromise(callback)) {
                        callback.catch(() => {
                            closeHandle();
                        });
                    } else if (isFunction(callback)) {
                        const inst = callback(action, instance, done);
                        if (isPromise(inst)) inst.then(() => closeHandle()).catch(() => closeHandle());
                    }
                } else {
                    done();
                    closeHandle();
                    reject(action, instance, done);
                }
            },
            ...options
        });
    });
};

/**
 * 查看漫画是否有新增章节未处理
 *
 * @param {Number} comicId
 * @param {String} originFile
 * @param {Number} pageIndex
 * @param {Number} pageSize
 * @returns {Boolean}
 */
export const hasDecompressedChapter = async ({ comicId, originFile, pageIndex = 1, pageSize = 500 }) => {
    const res = await http('getDecompressChapter', { comicId, originFile, pageIndex, pageSize });
    if (res.data.rows.length) return true;
    else return false;
};

/**
 * 获取图片对象image
 *
 * @param {Number} comicId
 * @param {String} originFile
 * @param {Number} pageIndex
 * @param {Number} pageSize
 * @returns {Boolean}
 */
export const getImagePromise = imageFile => {
    return new Promise((resolve, reject) => {
        if (isFileData(imageFile)) {
            let img = new Image();
            img.src = URL.createObjectURL(imageFile);
            img.onload = e => {
                resolve(e.target);
            };
        } else if (isImageObj(imageFile)) resolve(imageFile);
        else resolve(null);
    });
};

/**
 * 获取文件名
 *
 * @param {String} filePath
 * @returns {Object}
 */
export const getFileName = filePath => {
    if (!isString(filePath)) return;
    return path.basename(filePath, path.extname(filePath));
};

/**
 * 计算一个
 *
 * @param {String} str
 * @returns {Object}
 */
export const getUnicode = str => {
    if (!isString(str)) return;
    return str.split('').reduce((total, item, index) => {
        total += item.charCodeAt();
        return total;
    }, 0);
};

/**
 * 检查图片地址是否以/开头
 *
 * @param {String} str
 */
export const filterImagePath = str => {
    if (!str || !isString(str)) return str;
    if (/\.com/.test(str)) {
        if (!/\.com\//.test(str)) {
            str = str.replace('.com', '.com/');
            return str;
        } else return str;
    } else if (/^\//.test(str)) return str;
    else return `/${str}`;
};

/**
 * 防抖 debounce 函数
 *
 * @param {function} fn
 * @param {number} delay ms 默认0，等同于fn直接执行
 * @returns {function}
 */
export const debounce = (fn, delay = 0) => {
    let timer = null;
    if (!isFunction(fn)) throw new TypeError(`${fn} is not a function`);
    return function debounceTarget() {
        let context = this;
        let args = arguments;
        if (delay) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => fn.apply(context, args), delay);
        } else fn.apply(context, args);
    };
};

/**
 * 节流 throttle 函数
 *
 * @param {function} fn
 * @param {number} [interval=0] 节流间隔
 * @param {number} [overtime=1000] 超时间隔，保证长时间等待后的第一次会执行
 * @returns {function}
 */
export const throttle = (fn, interval = 0, overtime = 1000) => {
    let preTime = Date.now();
    return function throttleTarget() {
        let context = this;
        let args = arguments;
        let now = Date.now();
        const _interval = now - preTime;
        if (_interval >= interval || _interval > overtime) {
            preTime = now;
            fn.apply(context, args);
        }
    };
};

/**
 * 创建tree
 *
 * @param {array} list 节点列表
 * @returns {array}
 */
export const createTree = function(list = []) {
    const tree = [];
    list = JSON.parse(JSON.stringify(list));
    list.forEach(node => {
        // if (!node.children) node.children = [];
        if (node.parent_id === 0) tree.push(node);
        else {
            const parentNode = list.find(n => n.id === node.parent_id);
            if (!parentNode) return;
            if (parentNode.children) {
                parentNode.children.push(node);
                parentNode.children.sort((a, b) => a.order_num - b.order_num);
            } else parentNode.children = [node];
        }
    });
    return tree;
};

/**
 * snakeCase string
 * @param  {String} str []
 * @return {String}     []
 */
export const snakeCase = function(str) {
    return str.replace(/([^A-Z])([A-Z])/g, function($0, $1, $2) {
        return $1 + '_' + $2.toLowerCase();
    });
};

export const reCovertTime = function(sec) {
    if (isNaN(+sec)) return '-';
    sec = parseInt(sec);
    let h = parseInt(sec / 3600) + '';
    let m = parseInt(sec % 3600 / 60) + '';
    let s = sec % 3600 % 60 + '';
    return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
};

/**
 * 分成统计，数值显示转换
 * @param  {number} n
 * @return {String} ''
 */
export const showIntDesc = function(n) {
    const intN = parseInt(n);
    const len = intN.toString().length;
    if (len > 10) {
        return parseInt(intN / 1e8) + '亿';
    }
    if (len > 8) {
        return (intN / 1e8).toFixed(1) + '亿';
    }
    if (len > 6) {
        return parseInt(intN / 1e4) + '万';
    }
    if (len > 5) {
        return (intN / 1e4).toFixed(1) + '万';
    }
    return n;
};
