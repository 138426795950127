/*
 * @Author: daipeng
 * @Date: 2018-09-03 16:35:04
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-09 14:37:04
 * @Description: 设置路由
 * @Company: 成都二次元动漫
 */

import { scrollBehavior } from '@/libs/utils';
import Login from '@/views/auth/loginIn';
import Join from '@/views/auth/loginUp';
import Main from '@/views/main';
import RouterView from '@/views/routerview';

// 全局路由
export const globalRoutes = [
    {
        name: 'login',
        path: '/login',
        meta: { title: '登录' },
        component: Login
    },
    {
        name: 'join',
        path: '/join',
        component: Join
    }
];

// 内容路由, 也代表了左侧导航栏的层级
export const mainMenuRoutes = [
    {
        name: 'welcome',
        powerKey: 'welcomeMenu',
        path: '/main/welcome',
        title: '首页',
        icon: 'el-icon-home',
        isMenu: false,
        meta: { title: '首页' },
        component: resolve => require(['@/views/main/welcome/index'], resolve)
    },
    {
        name: 'help',
        path: '/main/help',
        title: '帮助中心',
        icon: '',
        isMenu: false,
        meta: { title: '帮助中心' },
        component: resolve => require(['@/views/main/other/help'], resolve)
    },
    {
        name: 'comicManager',
        powerKey: 'comicManagerMenu',
        path: '/main/comic',
        title: '漫画管理',
        icon: 'el-icon-manage',
        meta: { title: '漫画管理' },
        isMenu: true,
        redirect: '/main/comic/myComic',
        component: RouterView,
        children: [
            {
                name: 'myComic',
                powerKey: 'myComicMenu',
                path: 'myComic',
                title: '我的漫画',
                meta: { title: '我的漫画' },
                isMenu: true,
                component: resolve => require(['@/views/main/comicManager/myComic'], resolve)
            },
            {
                name: 'editComic',
                path: 'editComic',
                title: '编辑漫画',
                meta: { title: '编辑漫画' },
                isMenu: false,
                component: resolve => require(['@/views/main/comicManager/editComic'], resolve)
            },
            {
                name: 'editComicChapter',
                path: 'editComicChapter',
                title: '编辑章节',
                meta: { title: '编辑章节' },
                isMenu: false,
                component: resolve => require(['@/views/main/comicManager/comicChapter/editComicChapter'], resolve)
            },
            {
                name: 'addComic',
                powerKey: 'addComicMenu',
                path: 'addComic',
                title: '新增漫画',
                meta: { title: '新增漫画' },
                isMenu: true,
                redirect: '/main/comic/addComic/comicInfo',
                component: resolve => require(['@/views/main/comicManager/addComic'], resolve),
                children: [
                    {
                        name: 'comicInfo',
                        path: 'comicInfo',
                        title: '新建漫画',
                        meta: { title: '新建漫画' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/comicManager/comicInfo'], resolve)
                    },
                    {
                        name: 'addChapter',
                        path: 'addChapter',
                        title: '新增章节',
                        meta: { title: '新增章节' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/comicManager/comicChapter/addChapter'], resolve)
                    },
                    {
                        name: 'editChapter',
                        path: 'editChapter',
                        title: '编辑章节',
                        meta: { title: '编辑章节' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/comicManager/comicChapter/editChapter'], resolve)
                    },
                    {
                        name: 'addPublish',
                        path: 'addPublish',
                        title: '发布审核',
                        meta: { title: '发布审核' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/comicManager/addComic/addPublish'], resolve)
                    }
                ]
            }
        ]
    },
    {
        name: 'labelManager',
        powerKey: 'labelManager',
        path: '/main/comic',
        title: '标签管理',
        icon: 'el-icon-manage',
        meta: { title: '标签管理' },
        isMenu: true,
        redirect: '/main/comic/labelSetting',
        component: RouterView,
        children: [
            {
                name: 'labelSetting',
                powerKey: 'labelSetting',
                path: 'labelSetting',
                title: '标签设置',
                meta: { title: '标签设置' },
                isMenu: false,
                component: resolve => require(['@/views/main/labelManager/labelSetting'], resolve)
            },
            {
                name: 'labelConfig',
                powerKey: 'labelConfig',
                path: '/main/adminCenter/labelConfig',
                title: '标签配置',
                meta: { title: '标签配置' },
                isMenu: false,
                component: resolve => require(['@/views/main/labelManager/labelConfig'], resolve)
            }
        ]
    },
    {
        name: 'personalCenter',
        powerKey: 'personalCenterMenu',
        path: '/main/personalCenter',
        title: '个人中心',
        icon: 'el-icon-user',
        meta: { title: '个人中心' },
        isMenu: true,
        redirect: '/main/personalCenter/personalMessage',
        component: RouterView,
        children: [
            {
                name: 'personalMessage',
                powerKey: 'personalMessageMenu',
                path: 'personalMessage',
                title: '我的消息',
                meta: { title: '我的消息' },
                isMenu: true,
                component: resolve => require(['@/views/main/personalCenter/personalMessage'], resolve)
            },
            {
                name: 'personalHistory',
                powerKey: 'personalHistoryMenu',
                path: 'personalHistory',
                title: '操作历史',
                meta: { title: '操作历史' },
                isMenu: true,
                component: resolve => require(['@/views/main/personalCenter/personalHistory'], resolve)
            }
        ]
    },
    {
        name: 'adminCenter',
        powerKey: 'adminCenterMenu',
        path: '/main/adminCenter',
        title: '管理中心',
        icon: 'el-icon-rights',
        meta: { title: '管理中心' },
        isMenu: true,
        component: RouterView,
        children: [
            {
                name: 'userList',
                powerKey: 'userListMenu',
                path: 'userList',
                title: '用户列表',
                meta: { title: '用户列表' },
                isMenu: true,
                component: resolve => require(['@/views/main/adminCenter/userList'], resolve)
            },
            {
                name: 'powerSetting',
                powerKey: 'powerSettingMenu',
                path: 'powerSetting',
                title: '权限设置',
                meta: { title: '权限设置' },
                isMenu: true,
                component: resolve => require(['@/views/main/adminCenter/powerSetting'], resolve)
            },
            {
                name: 'auditComic',
                powerKey: 'auditComicMenu',
                path: 'auditComic',
                title: '漫画审核列表',
                meta: { title: '漫画审核列表' },
                isMenu: true,
                component: resolve => require(['@/views/main/adminCenter/auditComic'], resolve)
            },
            {
                name: 'auditComicInfo',
                powerKey: 'auditComicInfoMenu',
                path: 'auditComicInfo',
                title: '漫画审核详情',
                meta: { title: '漫画审核详情' },
                isMenu: false,
                component: resolve => require(['@/views/main/adminCenter/auditComic/auditComicInfo'], resolve)
            },
            {
                name: 'auditRecordList',
                powerKey: 'auditRecordList',
                path: 'auditRecordList',
                title: '审核记录列表',
                meta: { title: '审核记录列表' },
                isMenu: true,
                component: resolve => require(['@/views/main/adminCenter/auditRecordList'], resolve)
            }
        ]
    },
    {
        name: 'anComicManager',
        powerKey: 'anComicManagerMenu',
        path: '/main/anComic',
        title: '管理动态漫',
        icon: 'el-icon-manage',
        meta: { title: '管理动态漫' },
        isMenu: true,
        redirect: '/main/anComic/myAnComic',
        component: RouterView,
        children: [
            {
                name: 'myAnComic',
                powerKey: 'myAnComicMenu',
                path: 'myAnComic',
                title: '我的动态漫',
                meta: { title: '我的动态漫' },
                isMenu: true,
                component: resolve => require(['@/views/main/anComicManager/myComic'], resolve)
            },
            {
                name: 'anComicRelatedVideoList',
                path: 'anComicRelatedVideoList',
                title: '相关视频',
                meta: { title: '相关视频' },
                isMenu: false,
                component: resolve => require(['@/views/main/anComicManager/relatedVideo/list'], resolve)
            },
            {
                name: 'editAnComic',
                path: 'editAnComic',
                title: '编辑动态漫',
                meta: { title: '编辑动态漫' },
                isMenu: false,
                component: resolve => require(['@/views/main/anComicManager/editComic'], resolve)
            },
            {
                name: 'editAnComicChapter',
                path: 'editAnComicChapter',
                title: '编辑章节',
                meta: { title: '编辑章节' },
                isMenu: false,
                component: resolve => require(['@/views/main/anComicManager/comicChapter/editComicChapter'], resolve)
            },
            {
                name: 'addAnComic',
                powerKey: 'addAnComicMenu',
                path: 'addAnComic',
                title: '新增动态漫',
                meta: { title: '新增动态漫' },
                isMenu: true,
                redirect: '/main/anComic/addAnComic/anComicInfo',
                component: resolve => require(['@/views/main/anComicManager/addComic'], resolve),
                children: [
                    {
                        name: 'anComicInfo',
                        path: 'anComicInfo',
                        title: '新建动态漫',
                        meta: { title: '新建动态漫' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/anComicManager/comicInfo'], resolve)
                    },
                    {
                        name: 'editAnChapter',
                        path: 'editAnChapter',
                        title: '编辑章节',
                        meta: { title: '编辑章节' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/anComicManager/comicChapter/editChapter'], resolve)
                    },
                    {
                        name: 'addPublishAn',
                        path: 'addPublishAn',
                        title: '发布审核',
                        meta: { title: '发布审核' },
                        isMenu: false,
                        component: resolve => require(['@/views/main/anComicManager/addComic/addPublish'], resolve)
                    }
                ]
            },
            {
                name: 'relatedVideo',
                powerKey: 'relatedVideoMenu',
                path: 'relatedVideo',
                title: '相关视频',
                meta: { title: '相关视频' },
                isMenu: true,
                component: resolve => require(['@/views/main/anComicManager/relatedVideo'], resolve)
            },
            {
                name: 'addRelatedVideo',
                powerKey: 'addRelatedVideoMenu',
                path: 'addRelatedVideo',
                title: '新增相关视频',
                meta: { title: '我的动态漫' },
                isMenu: false,
                component: resolve => require(['@/views/main/anComicManager/relatedVideo/editVideo'], resolve)
            },
            {
                name: 'editRelatedVideo',
                powerKey: 'editRelatedVideoMenu',
                path: 'editRelatedVideo',
                title: '编辑相关视频',
                meta: { title: '我的动态漫' },
                isMenu: false,
                component: resolve => require(['@/views/main/anComicManager/relatedVideo/editVideo'], resolve)
            },
            {
                name: 'viewRelatedVideo',
                powerKey: 'viewRelatedVideoMenu',
                path: 'viewRelatedVideo',
                title: '查看相关视频',
                meta: { title: '我的动态漫' },
                isMenu: false,
                component: resolve => require(['@/views/main/anComicManager/relatedVideo/editVideo'], resolve)
            }
        ]
    },
    {
        name: 'divideManager',
        powerKey: 'divideManagerMenu',
        path: '/main/divideManager',
        title: '结算记录',
        icon: 'el-icon-date',
        meta: { title: '结算记录' },
        isMenu: true,
        redirect: '/main/divideManager/payDetail',
        component: RouterView,
        children: [
            {
                name: 'payDetail',
                powerKey: 'payDetailMenu',
                path: 'payDetail',
                title: '付费收入明细',
                meta: { title: '付费收入明细' },
                isMenu: true,
                component: resolve => require(['@/views/main/divideManager/payDetail'], resolve)
            },
            {
                name: 'offerDetail',
                powerKey: 'offerDetailMenu',
                path: 'offerDetail',
                title: '供养收入明细',
                meta: { title: '供养收入明细' },
                isMenu: true,
                component: resolve => require(['@/views/main/divideManager/offerDetail'], resolve)
            },
            {
                name: 'monthDetail',
                powerKey: 'monthDetailMenu',
                path: 'monthDetail',
                title: '月总收入报表',
                meta: { title: '月总收入报表' },
                isMenu: true,
                component: resolve => require(['@/views/main/divideManager/monthDetail'], resolve)
            }
        ]
    },
    {
        name: 'accountList',
        powerKey: 'accountListMenu',
        path: '/main/accountList',
        title: '财务账号管理',
        icon: 'el-icon-time',
        meta: { title: '财务账号管理' },
        isMenu: true,
        redirect: '/main/accountList/list',
        component: RouterView,
        children: [
            {
                name: 'list',
                powerKey: 'accountListManageMenu',
                path: 'list',
                title: '财务账号列表',
                meta: { title: '财务账号列表' },
                isMenu: true,
                component: resolve => require(['@/views/main/accountList/list'], resolve)
            }

        ]
    }
];

// 内容路由，但是不代表左侧导航组件
export const mianOtherRoutes = [];

// 异常页面路由
export const errorPageRoutes = [
    {
        name: 'error_404',
        path: '/*',
        component: resolve => require(['@/views/error/404'], resolve)
    }
];

// 所有的路由
export const allRoute = [
    ...globalRoutes,
    {
        name: 'main',
        path: '/main',
        // redirect: '/main/welcome',
        component: Main,
        children: [...mainMenuRoutes, ...mianOtherRoutes]
    }
];

// 默认注册路由
export default {
    base: '/',
    mode: 'history',
    linkActiveClass: 'router-link-active',
    linkExactActiveClass: 'router-link-exact-active',
    scrollBehavior,
    routes: allRoute
};
