/*
 * @Author: daipeng
 * @Date: 2018-09-10 15:35:38
 * @LastEditors: VSCode
 * @LastEditTime: 2019-04-19 23:21:44
 * @Description: 第三方插件
 * @Company: 成都二次元动漫
 */

import ElementUI from 'element-ui';
import lazyload from 'vue-lazyload';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/main.scss';

export const installPlugin = Vue => {
    Vue.use(ElementUI, { size: 'small' });

    // 全局加载图片懒加载公功能
    Vue.use(lazyload, {
        preLoad: 1.3,
        error: '/static/images/error.jpg',
        loading: '/static/images/loading.gif',
        adapter: {
            loaded({ el, src }) {},
            loading({el, loading, src}) {}
        },
        attempt: 1
    });
};
