/*
  创作价格显示逻辑，至于部分cp方可以编辑价格
 */

import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('app', {
            userId: state => state.userInfo.id,
            level_extra: state => state.userInfo.level_extra
        }),
        hasCreatePricePermission() {
            // 1、二次元动漫 25,480,481；2、极漫文化 170,430,464；3、金橙动漫 151,435；4、漫客文化 110,431；5、飒飒动漫 220,432；6、杭漫文化 96,436,458,459；7、仙娱文化 218,433,461,462
            // 1、二次元动漫 25,480,481；2、极漫文化 170；3、金橙动漫 151；4、漫客文化 110；5、飒飒动漫 220；6、杭漫文化 96；7、仙娱文化 218 ； 8、飒漫画 319； 9、漫客栈漫画 111
            // return [25, 480, 481, 170, 151, 110, 220, 96, 218, 319, 111].indexOf(this.userId) !== -1;
            // [1 创作价格 2 工作效率 3 批量修改创作价格]
            return this.level_extra.includes('1');
        }
    }
};
