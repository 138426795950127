/*
 * @Author: daipeng
 * @Date: 2018-09-10 15:16:36
 * @LastEditors: VSCode
 * @LastEditTime: 2019-03-08 16:57:37
 * @Description: 自定义指令
 * @Company: 成都二次元动漫
 */
import PScroll from 'perfect-scrollbar';

/**
 * @description 自动判断该更新PerfectScrollbar还是创建它
 * @param {HTMLElement} el - 必填。dom元素
 * @param {Object} direction 方向
 */
const el_scrollBar = (el, binding) => {
    // 在元素上加点私货，名字随便取，确保不会和已有属性重复即可，我取名叫做_ps_
    const { value = {} } = binding;
    if (el._ps_ instanceof PScroll) {
        el._ps_.update();
    } else {
        // el上挂一份属性
        el._ps_ = new PScroll(el, { suppressScrollX: true, ...value });
    }
};

const getNoDataDom = text => {
    const _dom = document.createElement('div');
    _dom.setAttribute('class', 'no-data');
    _dom.innerHTML = text;
    return _dom;
};

export const directives = {
    scrollbar: {
        // 使用inserted钩子函数（初次创建dom）获取使用自定义指令处的dom
        inserted(el, binding, vnode) {
            // 判断其样式是否存在position 并且position为'fixed', 'absolute'或'relative'
            // 如果不符合条件，抛个错误。当然你也可以抛个警告然顺便给其position自动加上'relative'
            // 为什么要这么做呢，因为PerfectScrollbar实现原理就是对dom注入两个div，一个是x轴一个是y轴，他们两的position都是absolute。
            // 对css稍有常识的人都知道，absolute是相对于所有父节点里设置了position属性的最近的一个节点来定位的，为了能够正确定位，我们要给其设置position属性
            const rules = ['fixed', 'absolute', 'relative'];
            if (!rules.includes(window.getComputedStyle(el, null).position)) {
                console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join('、')}`);
            }
            // el上挂一份属性
            el_scrollBar(el, binding);
        },
        // 更新dom的时候
        componentUpdated(el, binding, vnode, oldVnode) {
            try {
                // vnode.context其实就是vue实例，这里其实无需实例也直接用Vue的静态方法
                // 故而也可以写成Vue.nextTick
                vnode.context.$nextTick(() => {
                    el_scrollBar(el, binding);
                });
            } catch (error) {
                console.error(error);
                el_scrollBar(el, binding);
            }
        }
    },
    empty: {
        componentUpdated(el, binding, vnode, oldVnode) {
            const { value } = binding;
            const noEl = el.getElementsByClassName('no-data')[0];
            if (!value && !noEl) {
                el.appendChild(getNoDataDom('暂无数据'));
            } else if (value && noEl) {
                el.removeChild(noEl);
            }
        }
    },
    'acgn-number': { // 限制输入框只能输入数字 v-acgn-number="3" 数字代表几位小数
        bind(el, binding, vnode) {
            const input = el.querySelector('input');
            const numberCountAfterDot = binding.value; // 小数点后允许几位小数
            let reg = /^\d*$/;
            let reg1 = '';
            let emited = false; // 用于标记内部触发的input事件
            if (numberCountAfterDot) {
                reg = new RegExp(`^\\d*(?:\\.\\d{0,${numberCountAfterDot}})?$`);
                reg1 = new RegExp(`^(\\d+)\\.(\\d{${numberCountAfterDot}}).*$`);
            }

            vnode.componentInstance.$on('input', (value) => {
                if (emited) { // 如果是手动emit的，忽略掉
                    emited = false;
                    return;
                }
                let tempValue = value;
                const re = reg.test(tempValue);
                const nullValue = ['', ' ', '.'].includes(tempValue); // 判断是否为''|' '|.，表空值
                if (!nullValue && !re) { // 不为空值且验证不通过
                    if (numberCountAfterDot) { // 带小数点
                        tempValue = tempValue.replace(/[^\d.]/g, ''); // 清除'数字'和'.'以外的字符
                        tempValue = tempValue.replace(/^\./g, '0.'); // 保证第一个为数字而不是.
                        tempValue = tempValue.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.'); // 只保留第一个. 清除多余的
                        tempValue = tempValue.replace(reg1, '$1.$2'); // 只能输入N个小数
                    } else { // 正整型
                        tempValue = tempValue.replace(/[^\d]/g, '');
                    }
                }
                emited = true; // 赋值为true，用于input事件回调触发，标记后不进行后续处理
                const regValue = tempValue; // 记录处理后的字符串，用于后面做正则匹配细节优化
                tempValue = nullValue || tempValue === '' ? null : Number(tempValue);
                vnode.componentInstance.$emit('input', tempValue);

                let postfix = ''; // 如果输入为类似1.00，则保留输入框中的.00
                const matchs = regValue.match(/^\d+\.+(\d*?)(0*)$/);
                if (numberCountAfterDot && matchs !== null) {
                    const len = matchs[1].length;
                    const resLen = matchs[2].substring(0, numberCountAfterDot - len);
                    const dot = tempValue.toString().indexOf('.') === -1 ? '.' : '';
                    postfix = dot + resLen;
                }

                // el-input内部会在下一个时间序改变input.value，所以自定义指令在后2个时间序进行覆盖值
                vnode.componentInstance.$nextTick(() => {
                    vnode.componentInstance.$nextTick(() => {
                        input.value = tempValue === null ? null : (tempValue + postfix);
                    });
                });
            });
        }
    }
};

// 注册全局自定义指令
export const installDirective = Vue => {
    Object.keys(directives).forEach(name => {
        Vue.directive(name, directives[name]);
    });
};
