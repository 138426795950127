<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-12 17:01:13
 * @Description:
 -->
<template>
    <div :class="['video', 'flex-row', { isMouseOver: isMouseOver, 'unshelve': comicData.online_show_status === 0}]" @mouseover="isMouseOver = true" @mouseout="isMouseOver = false">
        <template v-if="showSelect">
            <div v-show="isMouseOver && !isSelected" class="select-box" @click="selectComicHandle(true)"></div>
            <div v-show="isSelected" class="select-box check" @click="selectComicHandle(false)"><i class="el-icon-check"></i></div>
        </template>
        <div class="video-img">
            <img src="/static/space.gif" :key="comicData.correlation_cover_domain + $utils.filterImagePath(comicData.correlation_cover_16_8)" v-lazy="comicData.correlation_cover_domain + $utils.filterImagePath(comicData.correlation_cover_16_8)" alt="">
            <div v-if="comicData.online_show_status === 0">已下架</div>
        </div>
        <dl class="video-info">
            <dt class="flex-row-between">
                <div class="video-title flex-row">
                    <el-tooltip class="item" effect="light" :content="comicData.correlation_name" placement="top-start">
                        <span class="comic-name" :title="comicData.correlation_name" style="max-width:90px;">{{comicData.correlation_name}}</span>
                    </el-tooltip>
                    <el-button class="comic-btn-edit" type="text" size="mini" plains @click="editComicHandle(comicData)" :disabled="comicData.online_show_status === 0" icon="el-icon-edit">编辑</el-button>
                </div>
                <div :class="comicData.audit_status | auditAnStatusStyleFilter">
                    {{comicData.audit_status | auditAnStatusFilter}}
                    <el-tooltip effect="light" v-if="[-1, 3, 4].includes(comicData.audit_status)" placement="top">
                        <div slot="content">{{comicData.auditRemark || (comicData.audit_status === 5 ? '未通过' : '已通过')}}</div>
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
            </dt>
            <dd>
                <span class="comic-dispatch-label">创建时间：</span>{{comicData.create_time | timeFilter('yyyy年MM月dd日 hh:mm')}}
            </dd>
            <dd class="related-comic">
                <span class="comic-dispatch-label">关联动态漫：</span>
                <span class="comic-dispatch-content">
                    <template v-for="(item, index) in comicData.animation_correlation">
                      <span :key="item.animation_id" :title="item.animation_name">
                        {{item.animation_name}}
                      </span>
                      <template v-if="index + 1 !== comicData.animation_correlation.length"> 、</template>
                    </template>
                </span>
            </dd>
            <dd class="video-btns">
                <el-button size="mini" plains @click="previewHandle(comicData)" :disabled="comicData.online_show_status === 0">预览</el-button>
                <!-- 状态1、3 -> 待提交、未通过 显示提交按钮审核 -->
                <el-button v-if="[1,3,5].includes(comicData.audit_status)" size="mini" plains type="primary" @click="submitAudit(comicData)">提交审核</el-button>
            </dd>
        </dl>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-14 09:55:44
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-14 09:56:05
 * @Description: 动态漫卡片
 * @Company: 成都二次元动漫
 */
import { mapState } from 'vuex';
export default {
    props: {
        comicData: {
            type: Object,
            default: () => {}
        },
        showSelect: { type: Boolean, default: false },
        // table list
        list: {
            type: Array,
            required: false,
            default: () => []
        },
        selectComicList: {
            type: Array,
            required: false,
            default: () => []
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            isMouseOver: false
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        }),
        isSelected() {
            return this.selectComicList.includes(this.comicData);
        },
        couldRemoveComic() {
            // 普通CP账号 审核中的动态漫隐藏“删除”按钮
            if (this.level === 1 && [4, 2].includes(this.comicData.audit_status)) {
                return false;
            }
            return this.comicData.allow_delete === 1;
        }
    },
    methods: {
        // 选择动态漫
        selectComicHandle(isSelected) {
            const that = this;
            if (isSelected) this.$emit('update:selectComicList', [...this.selectComicList, this.comicData]);
            else {
                const selectList = this.selectComicList.reduce((arr, obj) => {
                    if (obj !== that.comicData) arr.push(obj);
                    return arr;
                }, []);
                this.$emit('update:selectComicList', selectList);
            }
        },
        // 预览相关视频
        previewHandle({correlation_video_id}) {
            if (!this.link) {
                this.link = document.createElement('a');
            }
            this.$api('getRelatedVideoPlayAuth', {
                correlation_video_id
            }).then(res => {
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${correlation_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        },
        // 编辑
        editComicHandle({ correlation_id }) {
            this.$router.push({ name: 'editRelatedVideo', query: { correlation_id, editType: 'edit' } });
        },
        // 提交审核
        submitAudit({ correlation_id }) {
            this.$utils.confirm('', '确认提交审核吗？', '提交中...', (action, instance, done) => {
                return this.$api('submitRelatedVideoAudit', { correlation_id }).then(res => {
                    // 更新当前相关视频卡片，因为卡片是数组项，所以需要更新数组
                    let _data = this.$utils.cloneDeep(this.list);
                    _data.splice(this.index, 1, { ...this.comicData, audit_status: 2 });
                    this.$emit('update:list', _data);
                });
            });
        }
    }
};
</script>
<style lang="scss">
    .video{
        position: relative;
        width: calc(50% - 20px);
        margin-right: 20px;
        min-width: 560px;
        height: 160px;
        background-color: #fff;
        border: 1px solid #e4e7ea;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        margin-bottom: 20px;
        &.unshelve {
           .video-img {
             filter: grayscale(1);
             > div {
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               height: 20px;
               line-height: 20px;
               background-color: rgba(255,255,255, .7);
               text-align: center;
               color: #666;
             }
           }
        }
        &.isMouseOver{
            box-shadow: 0px 0px 20px rgba(102, 102, 102, 0.35);
        }
        &-img{
            position: relative;
            width: 316px;
            min-width: 316px;
            height: 100%;
            background-color: #e4e7ea;
            border-right: 1px solid #e4e7ea;
            cursor: pointer;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        &-info{
            padding: 10px;
            flex-grow: 1;
            font-size: 14px;
            overflow: hidden;
            dt, dd {
                height: 30px;
                line-height: 30px;
                color: #606266;
            }
            .comic-name{
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .related-comic {
              display: flex;
              flex-wrap: nowrap;
              flex: 1;
              height: auto;
              white-space: nowrap;
            }
            .comic-dispatch-label {
              width: 6em;
              flex: 0 0 6em;
              white-space: nowrap;
              overflow: hidden;
            }
            .comic-dispatch-content {
              display: flex;
              flex-wrap: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              span {
                flex: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
        }
        &-title{
            align-items: center;
        }
        &-btns{
            font-size: 0;
            margin: 10px 0 0 0;
            .el-button{
                padding: 7px 10px;
            }
        }
        .comic-btn-edit{
            font-size: 12px;
            margin-left: 10px;
        }
        .select-box{
            position: absolute;
            top: -1px;
            left: -1px;
            width: 40px;
            height: 40px;
            z-index: 50;
            cursor: pointer;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid rgba(233, 233, 233, 1);
            &.check{
                background: rgba(24, 144, 255, 1);
                border: none;
                i{
                    color: #fff;
                    font-size: 24px;
                    margin: 8px;
                }
            }
        }
    }
</style>
