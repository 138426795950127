/*
 * @Author: daipeng
 * @Date: 2018-09-21 09:44:17
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-21 10:33:36
 * @Description: 对data的一些操作方法minx
 * @Company: 成都二次元动漫
 */
export default {
    methods: {
        resetDataField(fields) {
            if (!this.$utils.isString(fields)) return new Error('resetDataField params nead String');
            const sourceData = this.$options.data();
            const keys = fields.split('.');
            this.$data[keys[0]] = sourceData[keys[0]];
        }
    }
};
