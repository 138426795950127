/*
 * @Author: daipeng
 * @Date: 2018-09-19 19:29:18
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-22 17:55:46
 * @Description: 图片上传mixin
 * @Company: 成都二次元动漫
 */
import { isBlob, isFormData, isFileData } from '@/libs/utils';
import { instance } from '@/api/request';
import comicCoverUpload from '@/components/comic/comicCoverUpload';

export default {
    data() {
        return {
            tokenData: {
                cdn: null,
                fileName: null,
                key: null,
                token: null,
                uploadHost: null
            },
            uploadData: null
        };
    },
    created() {
        this.tokenData.cdn = this.$config.cdn;
    },
    methods: {
        // 上传图片方法
        uploadHandle(data, options = {}) {
            let formdata = new FormData();
            if (!data.file) return console.error('file为必填');
            // 兼容写法,外部传入token数据，方便复数上传
            this.tokenData = { ...this.tokenData, ...data };
            let { file, uploadHost, key, fileName } = this.tokenData;

            // 为照顾后端接口
            key = key.replace(/^\/{1}/g, '');
            Object.keys(this.tokenData).forEach(item => {
                if (item === 'key') formdata.append(item, key + fileName);
                else formdata.append(item, this.tokenData[item]);
            });

            // formdata.append('token', data.token || token);

            // formdata.append('key', key + fileName);

            if (isBlob(file) || isFormData(file) || isFileData(file)) formdata.append('file', file);
            else return Promise.reject(new Error('file type is wronge'));

            return instance({
                method: 'POST',
                url: uploadHost,
                data: formdata,
                headers: {'Content-Type': 'multipart/form-data'},
                ...options
            });
        },
        // 需要提交的数据
        setUploadData(uploadData) {
            this.uploadData = uploadData;
        },
        // 获取漫画封面上传token
        getComicCoverToken(fileName, uploaderUid) {
            return this.$api('getComicCoverToken', {fileName, uploaderUid}).then(res => (this.tokenData = { ...this.tokenData, ...res.data }));
        },
        // 获取漫画章节内容上传token
        getChapterContentToken({ comicId, chapterId, fileName, uploaderUid }) {
            return this.$api('getChapterContentToken', { comicId, chapterId, fileName, uploaderUid }).then(res => res.data);
        },
        // 获取漫画章节封面上传token
        getChapterCoverToken({ comicId, fileName, uploaderUid }) {
            return this.$api('getChapterCoverToken', { comicId, fileName, uploaderUid }).then(res => res.data);
        },
        // 压缩文件上传token
        decompressFileToken(comicId, fileName, uploaderUid) {
            return this.$api('decompressFile', {comicId, fileName, uploaderUid}).then(res => (this.tokenData = { ...this.tokenData, ...res.data }));
        }
    },
    components: {
        comicCoverUpload
    }
};
