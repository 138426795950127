/*
 * @Author: daipeng
 * @Date: 2018-09-07 12:07:44
 * @LastEditTime: 2018-09-08 16:02:43
 * @Description: search mixins
 * @Company: 成都二次元动漫
 */

const validateUID = (rules, value, callback, data) => {
    if (value === '' || value === null) callback();
    else if (!/^[0-9]*$/.test(value)) callback(new Error('请输入数字'));
    else callback();
};

export default {
    data() {
        return {
            searchData: {},
            searchRules: {
                user_id: [{ validator: validateUID, trigger: 'change' }]
            }
        };
    },
    methods: {
        // 重写此方法 改变选择
        changeSelectHanddle() {},
        // 重写此方法 提交搜索
        submitSearchHanddle() {},
        // 重置搜索内容,使用该功能item上必须要设置prop
        resetForm(formName) {
            if (!formName) return false;
            this.$refs[formName].resetFields();
        }
    }
};
