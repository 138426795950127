<template>
  <div class="customer-login">
    <el-container class="customer-login">
      <el-main>
        <div class="customer-login-con" @keydown.enter.stop="submitHandle">
          <figure class="customer-logo">
            <img src="/static/images/logo_new.jpg" width="350" height="234" />
            <p>客服查询系统</p>
          </figure>
          <el-form :model="form" ref="loginForm">
            <el-form-item prop="userName" :rules="rules.userName">
              <el-input
                v-model.trim="form.userName"
                placeholder="输入用户名"
                prefix-icon="el-icon-phone"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password" :rules="rules.password">
              <el-input
                v-model.trim="form.password"
                placeholder="输入密码"
                type="password"
                prefix-icon="el-icon-password"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="12">
                  <!-- <el-checkbox v-model="checked">自动登录</el-checkbox> -->
                </el-col>
                <el-col :span="12" class="customer-tar">
                  <!-- <router-link to="/forgert" class="customer-primary">忘记密码</router-link> -->
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item align="center">
              <el-button
                type="primary"
                @click="submitHandle"
                class="customer-percent"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-main>
      <el-footer class="login-footer">


        <br />Copyright&copy;2017 漫画分发中心<br />
        <a
          style="color: #fff"
          ref="nofollow"
          target="_blank"
          href="http://beian.miit.gov.cn"
          >蜀ICP备17035423号-2</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;成都二次元动漫有限公司
      </el-footer>

       <div  style="margin-top: 10px; text-align: center;">
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1764925270600318976&language=CN&certType=8&url=www.cnmanhua.com" target="_blank" style="position: relative;display: inline-block;height: 38px;">
        <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1764925270600318976=www.cnmanhua.com.svg"width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
       </div>
      <dialogPwd ref="dialogPwd"></dialogPwd>
    </el-container>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-03 13:28:29
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-03 13:42:45
 * @Description: 客服查询系统-登录
 * @Company: 成都二次元动漫
 */
import { formMixin } from '@/mixins'
import dialogPwd from '@/components/dialogPwd'
export default {
  mixins: [formMixin],
  data() {
    return {
      form: {
        userName: '',
        password: '',
      },
      rules: {
        userName: [{ equired: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码至少为六位', trigger: 'blur' },
        ],
      },
      checked: true,
    }
  },
  methods: {
    registerHanddle() {
      console.log('注册')
    },
    async submitHandle() {
      const valid = await this.validateForm('loginForm')
      if (!valid) return false
      this.$api('login', this.form).then(({ data }) => {
        this.$store.commit('app/setUserInfo', data)
        this.$message({
          type: 'success',
          message: '登录成功~',
          duration: '500',
          onClose: () => {
            if (data.needRstPwd) {
              this.$refs.dialogPwd.$emit('EV_SHOW')
              return false
            }
            if ([2, 3, 4].includes(data.level)) {
              this.$router.replace({ name: 'personalCenter' })
            } else if (data.level === 5) {
              this.$router.replace({ name: 'divideManager' })
            } else this.$router.replace({ name: 'welcome' })
          },
        })
      })
    },
  },
  components: {
    dialogPwd,
  },
}
</script>
<style lang="scss">
@import '../../styles/var';
.customer-login {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  background-image: url('/static/images/globel_bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  &-con {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    padding: 20px;
    transform: translate(-50%, -60%);
    background-color: $color-white;
    background-clip: padding-box;
    border: 8px solid rgba($color-primary-light-9, 0.5); // border-radius: $border-radius-base;
    &-header {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      padding: 30px 0;
    }
    .form-con {
      padding: 10px 0 0;
    }
    .login-tip {
      font-size: 10px;
      text-align: center;
      color: #c3c3c3;
    }
  }
  .customer-logo {
    margin: 0 0 20px 0;
    text-align: center;
    img {
      width: 200px;
      height: 64px;
    }
    p {
      display: none;
    }
  }
  .customer-title {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }
}

.login {
  &-weixin,
  &-qq,
  &-sina {
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    color: $color-text-regular;
    [class*='el-icon-'] {
      display: block;
      width: 36px;
      height: 36px;
      margin: 10px auto 5px;
      border: 1px solid $color-primary;
      border-radius: 50%;
      line-height: 36px;
      font-size: 20px;
      text-align: center;
    }
  }
  &-qq {
    [class*='el-icon-'] {
      border-color: $color-primary;
      color: $color-primary;
    }
  }
  &-sina {
    [class*='el-icon-'] {
      border-color: #eb7350;
      color: #eb7350;
    }
  }
  &-weixin {
    [class*='el-icon-'] {
      border-color: #609700;
      color: #609700;
    }
  }
  @at-root .customer-title {
    font-size: 14px;
    color: $color-text-regular;
  }
  @at-root &-footer {
    height: 40px !important;
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
    line-height: 1.25;
    color: $color-white;
  }
}

.three-party {
  padding-top: 10px;
  border-top: 1px solid $border-color-lighter;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px;
}
</style>
