<template>
    <div class="comic-cover upload-bg" :style="{width, height}">
        <el-upload
            class="comic-cover-upload"
            :show-file-list="false"
            ref="coverUpload"
            :drag="drag"
            :action="action"
            :multiple="false"
            :auto-upload="autoUpload"
            :http-request="httpRequest"
            :on-success="onSuccess"
            :on-progress="onProgress"
            :on-change="(file, fileList) => {
                // element-ui的upload组件需要清空上传列表，否则多选的时候会重复
                $refs.coverUpload.clearFiles();
                onChange(file, fileList, id)
            }"
            :before-upload="beforeUpload"
        >
            <div class="comic-cover-upload-inner" :style="{width, height}">
                <slot></slot>
            </div>
        </el-upload>
        <div class="comic-cover-mask">{{maskText}}</div>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-19 14:39:57
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-22 09:59:26
 * @Description: 漫画封面上传
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        id: String,
        width: String,
        height: String,
        image: String,
        limit: Number,
        accept: String,
        action: { type: String, default: '' },
        drag: { type: Boolean, default: false },
        maskText: { type: String, default: '点击上传' },
        autoUpload: { type: Boolean, default: false },
        httpRequest: { type: Function },
        onSuccess: { type: Function },
        onProgress: { type: Function },
        onChange: { type: Function },
        beforeUpload: { type: Function }
    },
    data() {
        return {};
    },
    methods: {
        eventHandle() {

        }
    }
};
</script>
<style lang="scss">
    .comic-cover{
        position: relative;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        // background: #f5f5f5;
        z-index: 5;
        &-upload{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 6;
            .el-upload{
                width: 100%;
                height: 100%;
                .el-upload-dragger{
                    width: 100%;
                    height: 100%;
                    border: none;
                    background: transparent;
                }
                &-inner{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &-mask{
            position: absolute;
            bottom: -1px;
            left: -1px;
            right: -1px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            font-size: 12px;
            color: #fff;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);
        }
    }
</style>
